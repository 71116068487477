/* Enhanced Footer Styles - Dark Theme */
:root {
  --primary-color: #0066cc;
  --accent-color: #00cc99;
  --bg-color: #111827;
  --bg-darker: #0d1320;
  --text-color: #ffffff;
  --text-secondary: #a0aec0;
  --border-color: rgba(255, 255, 255, 0.08);
  --shadow-color: rgba(0, 0, 0, 0.3);
  --tiktok-color: #ff0050;
  --instagram-color: #e1306c;
  --twitter-color: #1da1f2;
  --telegram-color: #0088cc;
  --transition-speed: 0.3s;
}

.footer-section {
  background-color: var(--bg-darker);
  color: var(--text-color);
  padding: 60px 0 20px;
  position: relative;
  box-shadow: 0 -10px 20px rgba(0, 0, 0, 0.1);
}

.footer-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 1px;
  background: linear-gradient(to right, 
    rgba(0, 102, 204, 0.1), 
    rgba(0, 102, 204, 0.5), 
    rgba(0, 204, 153, 0.5), 
    rgba(0, 204, 153, 0.1)
  );
}

.footer-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
}

/* Footer Main */
.footer-main {
  display: grid;
  grid-template-columns: 1fr;
  gap: 40px;
  margin-bottom: 40px;
}

@media (min-width: 768px) {
  .footer-main {
    grid-template-columns: 1.5fr 2fr 1fr;
    align-items: start;
  }
}

/* Brand Section */
.footer-brand {
  display: flex;
  flex-direction: column;
}

.footer-logo {
  font-size: 1.5rem;
  font-weight: 700;
  margin: 0 0 10px;
  display: flex;
  flex-direction: column;
  line-height: 1.2;
}

.footer-logo .brand-text {
  background: linear-gradient(to right, var(--primary-color), var(--accent-color));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
}

.footer-logo .brand-africa {
  font-size: 1rem;
  color: var(--text-secondary);
  font-weight: 600;
  letter-spacing: 1px;
}

.footer-tagline {
  font-size: 0.95rem;
  color: var(--text-secondary);
  margin: 10px 0 0;
  line-height: 1.6;
  max-width: 280px;
}

/* Footer Links */
.footer-links {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
}

.footer-links-column h4 {
  font-size: 1.1rem;
  font-weight: 600;
  margin: 0 0 15px;
  color: var(--text-color);
  position: relative;
  padding-bottom: 10px;
}

.footer-links-column h4::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 30px;
  height: 2px;
  background-color: var(--accent-color);
}

.footer-links-column ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-links-column li {
  margin-bottom: 12px;
}

.footer-links-column a {
  color: var(--text-secondary);
  text-decoration: none;
  font-size: 0.95rem;
  transition: all var(--transition-speed) ease;
  display: inline-block;
  position: relative;
}

.footer-links-column a:hover {
  color: var(--accent-color);
  transform: translateX(3px);
}

.footer-links-column a::before {
  content: '›';
  position: absolute;
  left: -15px;
  opacity: 0;
  transition: all var(--transition-speed) ease;
}

.footer-links-column a:hover::before {
  opacity: 1;
}

/* Social Section */
.footer-social h4 {
  font-size: 1.1rem;
  font-weight: 600;
  margin: 0 0 15px;
  color: var(--text-color);
  position: relative;
  padding-bottom: 10px;
}

.footer-social h4::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 30px;
  height: 2px;
  background-color: var(--accent-color);
}

.social-icons {
  display: flex;
  gap: 15px;
  margin-bottom: 20px;
}

.social-link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.05);
  color: var(--text-color);
  font-size: 1.1rem;
  transition: all var(--transition-speed) ease;
  box-shadow: 0 2px 5px var(--shadow-color);
}

.social-link:hover {
  transform: translateY(-3px);
  box-shadow: 0 5px 10px var(--shadow-color);
}

.social-link:nth-child(1):hover {
  background-color: var(--tiktok-color);
  color: white;
}

.social-link:nth-child(2):hover {
  background-color: var(--instagram-color);
  color: white;
}

.social-link:nth-child(3):hover {
  background-color: var(--twitter-color);
  color: white;
}

.social-link:nth-child(4):hover {
  background-color: var(--telegram-color);
  color: white;
}

/* Newsletter form */
.footer-newsletter {
  margin-top: 15px;
}

.newsletter-form {
  display: flex;
  margin-top: 10px;
}

.newsletter-input {
  flex: 1;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid var(--border-color);
  border-radius: 6px 0 0 6px;
  padding: 10px 15px;
  color: var(--text-color);
  font-size: 0.9rem;
}

.newsletter-input:focus {
  outline: none;
  border-color: var(--primary-color);
}

.newsletter-btn {
  background: linear-gradient(to right, var(--primary-color), var(--accent-color));
  color: white;
  border: none;
  border-radius: 0 6px 6px 0;
  padding: 0 15px;
  font-weight: 600;
  cursor: pointer;
  transition: all var(--transition-speed) ease;
}

.newsletter-btn:hover {
  opacity: 0.9;
}

/* Footer Bottom */
.footer-bottom {
  border-top: 1px solid var(--border-color);
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-bottom p {
  margin: 0 0 10px;
  font-size: 0.9rem;
  color: var(--text-secondary);
}

.footer-links-bottom {
  display: flex;
  gap: 20px;
  margin: 10px 0;
}

.footer-links-bottom a {
  color: var(--text-secondary);
  font-size: 0.85rem;
  text-decoration: none;
  transition: color var(--transition-speed) ease;
}

.footer-links-bottom a:hover {
  color: var(--accent-color);
}

.footer-disclaimer {
  font-size: 0.8rem;
  max-width: 750px;
  margin: 15px auto 0;
  color: var(--text-secondary);
  opacity: 0.8;
  text-align: center;
  line-height: 1.6;
}

/* Responsive Adjustments */
@media (max-width: 767px) {
  .footer-main {
    grid-template-columns: 1fr;
    gap: 30px;
  }
  
  .footer-links {
    grid-template-columns: 1fr;
    gap: 20px;
  }
  
  .footer-links-bottom {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
  
  .newsletter-form {
    flex-direction: column;
    gap: 10px;
  }
  
  .newsletter-input, .newsletter-btn {
    border-radius: 6px;
    width: 100%;
    padding: 10px;
  }
}