/* Auth.css - Matching your website's current style */

:root {
  --dark-blue: #121d38;
  --medium-blue: #1a2745;
  --light-blue: #2c3e67;
  --text-color: #ffffff;
  --text-secondary: #b9c1d5;
  --border-color: #2c3e67;
  --input-bg: #1a2745;
  --gradient-start: #0e77dd;
  --gradient-end: #12c49d;
  --success-color: #12c49d;
  --error-color: #f35757;
  --warning-color: #f3ba57;
}

/* Auth container - full page layout */
.auth-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 20px;
  background-color: var(--dark-blue);
}

/* Auth form styling */
.auth-form {
  width: 100%;
  max-width: 450px;
  padding: 40px 30px;
  background-color: var(--medium-blue);
  border-radius: 12px;
  border: 1px solid var(--border-color);
}

.auth-form h2 {
  margin-bottom: 25px;
  font-size: 1.75rem;
  font-weight: 600;
  color: var(--text-color);
  text-align: center;
}

/* Form inputs */
.auth-form input {
  width: 100%;
  padding: 14px;
  margin-bottom: 15px;
  background-color: var(--input-bg);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  color: var(--text-color);
  font-size: 1rem;
  transition: border-color 0.3s ease;
}

.auth-form input:focus {
  outline: none;
  border-color: var(--gradient-start);
}

.auth-form input::placeholder {
  color: var(--text-secondary);
  opacity: 0.7;
}

/* Password toggle */
.toggle-password {
  display: block;
  text-align: left;
  margin-bottom: 20px;
  color: var(--text-color);
  font-size: 0.9rem;
  cursor: pointer;
  transition: color 0.2s;
}

.toggle-password:hover {
  color: var(--gradient-end);
}

/* Password container */
.password-container {
  position: relative;
  margin-bottom: 5px;
}

/* Submit button */
.auth-button {
  width: 100%;
  padding: 14px;
  background: linear-gradient(to right, var(--gradient-start), var(--gradient-end));
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: opacity 0.3s;
  margin-top: 10px;
}

.auth-button:hover:not(:disabled) {
  opacity: 0.9;
}

.auth-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

/* Form footer */
.auth-form p {
  margin-top: 20px;
  text-align: center;
  color: var(--text-secondary);
  font-size: 0.95rem;
}

.auth-form p span {
  color: var(--success-color);
  cursor: pointer;
  font-weight: 500;
}

.auth-form p span:hover {
  text-decoration: underline;
}

/* Status messages */
.success-message, .error-message, .warning-message, .status-message, .reset-status {
  padding: 12px;
  border-radius: 8px;
  margin-bottom: 20px;
  font-size: 0.95rem;
  text-align: center;
}

.success-message {
  background-color: rgba(18, 196, 157, 0.1);
  color: var(--success-color);
}

.error-message {
  background-color: rgba(243, 87, 87, 0.1);
  color: var(--error-color);
}

.warning-message {
  background-color: rgba(243, 186, 87, 0.1);
  color: var(--warning-color);
}

.status-message, .reset-status {
  background-color: rgba(255, 255, 255, 0.05);
  color: var(--text-secondary);
}

/* Loading spinner */
.loading-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: var(--dark-blue);
  color: var(--text-color);
}

.loading-spinner {
  width: 40px;
  height: 40px;
  border: 4px solid rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  border-top-color: var(--gradient-end);
  animation: spin 1s linear infinite;
  margin-bottom: 20px;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Forgot password link */
.forgot-password {
  display: block;
  text-align: center;
  font-size: 0.9rem;
  color: var(--text-secondary);
  margin-top: 20px;
  margin-bottom: 20px;
  cursor: pointer;
}

.forgot-password:hover {
  color: var(--text-color);
}

/* Google Auth Button */
.google-auth-button {
  width: 100%;
  padding: 14px;
  background-color: var(--input-bg);
  color: var(--text-color);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s;
  margin-bottom: 15px;
}

.google-auth-button i {
  margin-right: 10px;
  font-size: 1.2rem;
  color: var(--gradient-start);
}

.google-auth-button:hover:not(:disabled) {
  background-color: var(--light-blue);
}

.google-auth-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

/* Auth divider */
.auth-divider {
  position: relative;
  margin: 20px 0;
  text-align: center;
}

.auth-divider::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  height: 1px;
  background-color: var(--border-color);
}

.auth-divider span {
  position: relative;
  padding: 0 10px;
  background-color: var(--medium-blue);
  color: var(--text-secondary);
  font-size: 0.9rem;
}

/* Verification Page Styling */
.verification-form {
  max-width: 500px;
  text-align: center;
}

.verification-icon {
  font-size: 60px;
  color: var(--gradient-end);
  margin: 20px 0;
}

.verification-icon.success {
  color: var(--success-color);
}

.verification-icon.error {
  color: var(--error-color);
}

.verification-message {
  margin-bottom: 25px;
}

.verification-message p {
  margin-bottom: 15px;
  color: var(--text-secondary);
}

.verification-message strong {
  color: var(--text-color);
}

.verification-actions {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin: 20px 0;
}

.resend-button {
  width: 100%;
  padding: 14px;
  background: linear-gradient(to right, var(--gradient-start), var(--gradient-end));
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: opacity 0.3s;
}

.resend-button:hover:not(:disabled) {
  opacity: 0.9;
}

.resend-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.login-redirect-button {
  padding: 14px;
  background-color: var(--input-bg);
  border: 1px solid var(--border-color);
  color: var(--text-color);
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 100%;
}

.login-redirect-button:hover {
  background-color: var(--light-blue);
}

.verification-help {
  margin-top: 25px;
  font-size: 0.9rem;
  color: var(--text-secondary);
}

.verification-help i {
  margin-right: 5px;
  color: var(--gradient-start);
}

.back-to-login {
  margin-top: 15px;
  text-align: center;
  color: var(--text-secondary);
  cursor: pointer;
}

.back-to-login:hover {
  color: var(--success-color);
  text-decoration: underline;
}

/* Return notice */
.return-notice {
  margin-top: 20px;
  color: var(--text-secondary);
  font-size: 0.9rem;
  text-align: center;
}

.return-notice i {
  margin-right: 5px;
  color: var(--gradient-start);
}

/* Responsive adjustments */
@media (max-width: 500px) {
  .auth-form {
    padding: 30px 20px;
  }
  
  .auth-form h2 {
    font-size: 1.5rem;
  }
}