/* Challenge Section Styles - Scoped to avoid conflicts */
/* Using #challenges prefix for all selectors to ensure specificity */

#challenges {
  --challenge-primary: #0066cc;
  --challenge-accent: #00cc99;
  --challenge-gradient: linear-gradient(135deg, #0066cc, #00cc99);
  --challenge-bg: #ffffff;
  --challenge-card-bg: #f9f9f9;
  --challenge-text: #2d3748;
  --challenge-text-secondary: #4a5568;
  --challenge-border: rgba(0, 0, 0, 0.08);
  --challenge-shadow: rgba(0, 0, 0, 0.05);
  --challenge-beginner: #00cc99;
  --challenge-intermediate: #0066cc;
  --challenge-advanced: #cc6600;
  --challenge-hover-transform: translateY(-6px);
  --challenge-button-hover-shadow: 0 6px 12px rgba(0, 102, 204, 0.15);
}

/* Dark mode support */
@media (prefers-color-scheme: dark) {
  #challenges {
    --challenge-primary: #4299e1;
    --challenge-accent: #38b2ac;
    --challenge-gradient: linear-gradient(135deg, #4299e1, #38b2ac);
    --challenge-bg: #1a202c;
    --challenge-card-bg: #2d3748;
    --challenge-text: #f7fafc;
    --challenge-text-secondary: #e2e8f0;
    --challenge-border: rgba(255, 255, 255, 0.08);
    --challenge-shadow: rgba(0, 0, 0, 0.3);
    --challenge-button-hover-shadow: 0 6px 12px rgba(66, 153, 225, 0.25);
  }
}

#challenges.trading-challenges-section {
  padding: clamp(3rem, 8vw, 6rem) clamp(1rem, 5vw, 2rem);
  background-color: var(--challenge-bg);
  color: var(--challenge-text);
  transition: all 0.3s ease;
}

#challenges .section-container {
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 350px;
  grid-template-areas:
    "header header"
    "challenges sidebar"
    "rules rules";
  gap: 2.5rem;
}

@media (max-width: 1024px) {
  #challenges .section-container {
    grid-template-columns: 1fr;
    grid-template-areas:
      "header"
      "challenges"
      "sidebar"
      "rules";
  }
}

/* Section Header */
#challenges .section-header {
  grid-area: header;
  text-align: center;
  margin-bottom: clamp(2rem, 6vw, 3rem);
}

#challenges .section-title {
  font-size: clamp(1.8rem, 5vw, 2.75rem);
  font-weight: 800;
  margin-bottom: 1.25rem;
  letter-spacing: -0.025em;
  line-height: 1.2;
  color: var(--challenge-text);
}

#challenges .section-title .highlight {
  color: var(--challenge-accent);
  position: relative;
  display: inline-block;
}

#challenges .section-title .highlight::after {
  content: '';
  position: absolute;
  bottom: 0.1em;
  left: 0;
  width: 100%;
  height: 0.3em;
  background-color: rgba(0, 204, 153, 0.2);
  z-index: -1;
  border-radius: 2px;
  transform: skewX(-5deg);
}

#challenges .section-description {
  font-size: clamp(1rem, 2vw, 1.15rem);
  color: var(--challenge-text-secondary);
  max-width: 700px;
  margin: 0 auto;
  line-height: 1.7;
}

/* Challenges Grid */
#challenges .challenges-grid {
  grid-area: challenges;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

/* Challenge Cards */
#challenges .challenge-card {
  background-color: var(--challenge-card-bg);
  border: 1px solid var(--challenge-border);
  border-radius: 16px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  box-shadow: 0 10px 25px var(--challenge-shadow);
  transition: transform 0.4s cubic-bezier(0.3, 0, 0.2, 1.5), 
              box-shadow 0.4s cubic-bezier(0.3, 0, 0.2, 1);
  position: relative;
  isolation: isolate;
  padding-bottom: 1.5rem;
}

#challenges .challenge-card:hover {
  transform: var(--challenge-hover-transform);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.12);
}

@media (min-width: 768px) {
  #challenges .challenge-card {
    flex-direction: row;
    padding-bottom: 0;
  }
  
  #challenges .challenge-image {
    flex: 0 0 240px;
  }
}

#challenges .challenge-image {
  position: relative;
  height: 200px;
}

#challenges .challenge-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease;
}

#challenges .challenge-card:hover .challenge-image img {
  transform: scale(1.05);
}

#challenges .difficulty-badge {
  position: absolute;
  top: 16px;
  right: 16px;
  padding: 0.45rem 1.1rem;
  border-radius: 9999px;
  font-size: 0.8rem;
  font-weight: 700;
  letter-spacing: 0.01em;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(4px);
  transition: all 0.3s ease;
}

#challenges .challenge-content {
  padding: 1.75rem 1.75rem 2.5rem;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
}

#challenges .challenge-title {
  font-size: 1.4rem;
  font-weight: 700;
  margin-bottom: 1rem;
  line-height: 1.3;
  color: var(--challenge-text);
  transition: color 0.3s ease;
}

#challenges .challenge-card:hover .challenge-title {
  background: var(--challenge-gradient);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

#challenges .challenge-description {
  margin-bottom: 1.5rem;
  color: var(--challenge-text-secondary);
  line-height: 1.7;
  flex-grow: 1;
  font-size: 0.95rem;
}

/* Challenge Meta */
#challenges .challenge-meta {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5rem;
  gap: 1rem;
}

#challenges .meta-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#challenges .meta-value {
  font-size: 1.3rem;
  font-weight: 800;
  color: var(--challenge-accent);
}

#challenges .meta-label {
  font-size: 0.8rem;
  color: var(--challenge-text-secondary);
  text-transform: uppercase;
  letter-spacing: 0.05em;
  font-weight: 600;
}

#challenges .join-challenge-btn {
  background: var(--challenge-gradient);
  color: white;
  border: none;
  padding: 0.8rem 1.75rem;
  border-radius: 8px;
  font-weight: 700;
  font-size: 0.95rem;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
  margin-bottom: 1rem;
  width: auto;
  min-width: 180px;
  text-align: center;
  align-self: center;
}

#challenges .join-challenge-btn::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0));
  transform: translateX(-100%);
  transition: transform 0.6s ease;
}

#challenges .join-challenge-btn:hover {
  transform: translateY(-3px);
  box-shadow: var(--challenge-button-hover-shadow);
}

#challenges .join-challenge-btn:hover::before {
  transform: translateX(100%);
}

/* Sidebar */
#challenges .challenges-sidebar {
  grid-area: sidebar;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

/* Leaderboard */
#challenges .leaderboard-banner {
  background-color: var(--challenge-card-bg);
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 10px 25px var(--challenge-shadow);
  border: 1px solid var(--challenge-border);
}

#challenges .leaderboard-header {
  background: var(--challenge-gradient);
  padding: 1rem 1.5rem;
  color: white;
  text-align: center;
}

#challenges .leaderboard-header h3 {
  font-size: 1.2rem;
  font-weight: 700;
  margin: 0;
}

#challenges .leaderboard-content {
  padding: 1rem;
}

#challenges .leaderboard-item {
  display: flex;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid var(--challenge-border);
}

#challenges .leaderboard-item:last-child {
  border-bottom: none;
}

#challenges .rank {
  font-size: 1.5rem;
  margin-right: 1rem;
}

#challenges .trader-avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 1rem;
  flex-shrink: 0;
}

#challenges .trader-avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

#challenges .trader-info {
  flex-grow: 1;
}

#challenges .trader-name {
  font-weight: 700;
  font-size: 1rem;
  margin-bottom: 0.3rem;
  color: var(--challenge-text);
}

#challenges .trader-country {
  font-size: 0.85rem;
  color: var(--challenge-text-secondary);
  margin-bottom: 0.3rem;
}

#challenges .trader-stats {
  display: flex;
  gap: 1rem;
  font-size: 0.75rem;
  color: var(--challenge-text-secondary);
}

#challenges .win-rate {
  color: var(--challenge-accent);
  font-weight: 600;
}

#challenges .trader-points {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#challenges .points-value {
  font-weight: 800;
  font-size: 1.3rem;
  color: var(--challenge-primary);
}

#challenges .points-label {
  font-size: 0.8rem;
  color: var(--challenge-text-secondary);
}

/* Upcoming Challenges */
#challenges .upcoming-challenges {
  background-color: var(--challenge-card-bg);
  border-radius: 16px;
  padding: 1.5rem;
  box-shadow: 0 10px 25px var(--challenge-shadow);
  border: 1px solid var(--challenge-border);
}

#challenges .upcoming-challenges h3 {
  font-size: 1.2rem;
  font-weight: 700;
  margin-bottom: 1.2rem;
  color: var(--challenge-text);
  text-align: center;
  position: relative;
}

#challenges .upcoming-challenges h3::after {
  content: '';
  position: absolute;
  bottom: -8px;
  left: 50%;
  transform: translateX(-50%);
  width: 50px;
  height: 3px;
  background: var(--challenge-gradient);
  border-radius: 3px;
}

#challenges .upcoming-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

#challenges .upcoming-item {
  display: flex;
  gap: 1rem;
  padding: 1rem 0;
  border-bottom: 1px solid var(--challenge-border);
}

#challenges .upcoming-item:last-child {
  border-bottom: none;
}

#challenges .upcoming-date {
  background: var(--challenge-primary);
  color: white;
  padding: 0.5rem;
  border-radius: 6px;
  font-weight: 700;
  font-size: 0.85rem;
  height: fit-content;
  min-width: 60px;
  text-align: center;
}

#challenges .upcoming-info h4 {
  font-size: 1rem;
  font-weight: 700;
  margin: 0 0 0.5rem 0;
  color: var(--challenge-text);
}

#challenges .upcoming-info p {
  font-size: 0.9rem;
  color: var(--challenge-text-secondary);
  margin: 0;
  line-height: 1.5;
}

/* Challenge Rules */
#challenges .challenge-rules-summary {
  grid-area: rules;
  background-color: var(--challenge-card-bg);
  border-radius: 16px;
  padding: 2rem;
  box-shadow: 0 10px 25px var(--challenge-shadow);
  border: 1px solid var(--challenge-border);
  margin-top: 1rem;
}

#challenges .challenge-rules-summary h3 {
  font-size: 1.3rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
  color: var(--challenge-text);
  text-align: center;
}

#challenges .challenge-rules-summary ul {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
  list-style: none;
  padding: 0;
  margin: 0;
}

#challenges .challenge-rules-summary li {
  position: relative;
  padding-left: 2rem;
  font-size: 1rem;
  color: var(--challenge-text-secondary);
  line-height: 1.6;
}

#challenges .challenge-rules-summary li::before {
  content: "✓";
  position: absolute;
  left: 0;
  top: 0;
  color: var(--challenge-accent);
  font-weight: 700;
  font-size: 1.2rem;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  #challenges .challenge-card {
    flex-direction: column;
    max-height: none;
  }
  
  #challenges .challenge-image {
    flex: none;
    height: 180px;
  }
  
  #challenges .join-challenge-btn {
    margin-bottom: 0.5rem;
  }
  
  #challenges .challenge-rules-summary ul {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
}

@media (min-width: 768px) {
  #challenges .join-challenge-btn {
    align-self: flex-start;
    margin-top: 0.5rem;
  }
}

@media (max-width: 480px) {
  #challenges .challenge-meta {
    flex-wrap: wrap;
    justify-content: center;
  }
  
  #challenges .meta-item {
    flex-basis: 45%;
  }
  
  #challenges .leaderboard-item {
    flex-wrap: wrap;
  }
  
  #challenges .trader-avatar {
    margin-right: 0.5rem;
  }
  
  #challenges .trader-info {
    flex: 1 0 60%;
  }
  
  #challenges .trader-points {
    flex: 1 0 100%;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    margin-top: 0.5rem;
  }
}