/* Community Section Styles */
:root {
  --primary-color: #0066cc;
  --accent-color: #00cc99;
  --female-accent: #ff6b6b;
  --bg-color: #111827;
  --card-bg: rgba(24, 31, 46, 0.7);
  --text-color: #ffffff;
  --text-secondary: #a0aec0;
  --border-color: rgba(255, 255, 255, 0.08);
  --success-color: #00cc99;
  --warning-color: #f59e0b;
  --error-color: #ef4444;
}

.community-section {
  padding: 80px 0;
  background-color: var(--bg-color);
  color: var(--text-color);
}

.community-container {
  max-width: 1100px;
  margin: 0 auto;
  padding: 0 20px;
}

/* Header */
.community-header {
  text-align: center;
  margin-bottom: 50px;
}

.community-title {
  font-size: 2.2rem;
  font-weight: 700;
  margin-bottom: 12px;
}

.community-title .highlight {
  color: var(--accent-color);
  position: relative;
}

.community-description {
  font-size: 1.1rem;
  color: var(--text-secondary);
  max-width: 700px;
  margin: 0 auto;
  line-height: 1.6;
}

/* Community Cards */
.community-cards {
  display: flex;
  gap: 30px;
  margin-bottom: 60px;
}

@media (max-width: 900px) {
  .community-cards {
    flex-direction: column;
  }
}

.community-card {
  flex: 1;
  background-color: var(--card-bg);
  border: 1px solid var(--border-color);
  border-radius: 12px;
  padding: 30px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.community-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 12px 28px rgba(0, 0, 0, 0.25);
}

.card-icon {
  font-size: 2.2rem;
  color: var(--primary-color);
  margin-bottom: 1.5rem;
}

.community-card h3 {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 16px;
  color: var(--text-color);
}

.community-card p {
  color: var(--text-secondary);
  margin-bottom: 24px;
  line-height: 1.6;
}

/* Discord Card */
.discord-card {
  border-top: 3px solid var(--primary-color);
}

.community-benefits {
  list-style: none;
  padding: 0;
  margin-bottom: 30px;
}

.community-benefits li {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  color: var(--text-secondary);
}

.benefit-icon {
  color: var(--accent-color);
  margin-right: 12px;
  font-size: 1.1rem;
}

.discord-btn {
  display: inline-block;
  background: linear-gradient(to right, var(--primary-color), var(--accent-color));
  color: white;
  padding: 14px 24px;
  border-radius: 8px;
  text-decoration: none;
  font-weight: 600;
  transition: all 0.2s ease;
}

.discord-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 10px rgba(0, 102, 204, 0.3);
}

/* Female Traders Card */
.female-traders-card {
  border-top: 3px solid var(--female-accent);
}

.card-header {
  margin-bottom: 24px;
}

.special-badge {
  background-color: var(--female-accent);
  color: white;
  padding: 6px 16px;
  border-radius: 50px;
  font-size: 0.9rem;
  font-weight: 600;
}

.female-traders-features {
  margin-bottom: 30px;
}

.feature {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.feature-icon {
  color: var(--female-accent);
  margin-right: 12px;
  font-size: 1.1rem;
}

.female-traders-btn {
  display: inline-block;
  background: linear-gradient(to right, #ff6b6b, #ff8e8e);
  color: white;
  padding: 14px 24px;
  border-radius: 8px;
  text-decoration: none;
  font-weight: 600;
  transition: all 0.2s ease;
}

.female-traders-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 10px rgba(255, 107, 107, 0.3);
}

/* Testimonials */
.community-testimonials {
  text-align: center;
  margin-top: 60px;
}

.community-testimonials h3 {
  font-size: 1.8rem;
  font-weight: 700;
  margin-bottom: 30px;
  color: var(--text-color);
}

.testimonials-container {
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
  justify-content: center;
}

.testimonial {
  flex: 1;
  min-width: 300px;
  background-color: var(--card-bg);
  border: 1px solid var(--border-color);
  border-radius: 12px;
  padding: 30px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
  position: relative;
}

.testimonial p {
  font-style: italic;
  font-size: 1.1rem;
  color: var(--text-color);
  line-height: 1.6;
  margin-bottom: 16px;
}

.testimonial::before {
  content: '"';
  font-size: 3rem;
  color: rgba(0, 204, 153, 0.2);
  position: absolute;
  top: 10px;
  left: 15px;
  font-family: 'Georgia', serif;
}

.testimonial-author {
  font-weight: 600;
  color: var(--accent-color);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .community-section {
    padding: 60px 0;
  }
  
  .community-title {
    font-size: 1.8rem;
  }
  
  .community-description {
    font-size: 1rem;
  }
  
  .testimonials-container {
    flex-direction: column;
  }
}

@media (max-width: 500px) {
  .community-card {
    padding: 20px;
  }
}