/* events.css - Complete styling for events page */

/* Main Container */
.events-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

/* Page Header */
.page-header {
  background: linear-gradient(135deg, #1a237e 0%, #2b7de9 100%);
  color: white;
  border-radius: 12px;
  padding: 40px 30px;
  margin-bottom: 30px;
  text-align: center;
}

.header-content {
  max-width: 800px;
  margin: 0 auto;
}

.page-header h1 {
  font-size: 2.5rem;
  margin-bottom: 15px;
  font-weight: 700;
}

.page-header p {
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 10px;
  opacity: 0.9;
}

/* Page Content */
.page-content {
  position: relative;
}

/* Search Bar */
.search-container {
  margin-bottom: 20px;
}

.search-bar {
  display: flex;
  align-items: center;
  background-color: white;
  border-radius: 8px;
  padding: 10px 15px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.search-icon {
  color: #666;
  margin-right: 10px;
  font-size: 16px;
}

.search-bar input {
  flex: 1;
  border: none;
  padding: 8px 0;
  font-size: 1rem;
  outline: none;
}

.clear-search {
  background: none;
  border: none;
  color: #666;
  font-size: 1.5rem;
  cursor: pointer;
  padding: 0 5px;
}

.clear-search:hover {
  color: #333;
}

/* Filters */
.filters-container {
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  margin-bottom: 20px;
  overflow: hidden;
}

.main-filters {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 15px;
  border-bottom: 1px solid #eee;
}

.type-filters {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.filter-btn {
  background: none;
  border: 1px solid #e0e0e0;
  border-radius: 20px;
  padding: 8px 15px;
  font-size: 0.9rem;
  color: #555;
  cursor: pointer;
  transition: all 0.2s ease;
}

.filter-btn:hover {
  background-color: #f5f5f5;
}

.filter-btn.active {
  background-color: #2b7de9;
  color: white;
  border-color: #2b7de9;
}

.filter-toggles {
  display: flex;
  align-items: center;
  gap: 15px;
}

.advanced-filter-btn {
  background: none;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  padding: 8px 12px;
  font-size: 0.9rem;
  color: #555;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: all 0.2s ease;
}

.advanced-filter-btn:hover,
.advanced-filter-btn.active {
  background-color: #f5f5f5;
}

.toggle-label {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  font-size: 0.9rem;
  color: #555;
}

.toggle-label input {
  cursor: pointer;
}

.toggle-text {
  user-select: none;
}

.advanced-filters {
  background-color: #f9f9fa;
  padding: 15px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  overflow: hidden;
}

.filter-group {
  flex: 1;
  min-width: 250px;
}

.filter-group h4 {
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 10px;
}

.filter-options {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.category-filter,
.difficulty-filter {
  background-color: white;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  padding: 6px 12px;
  font-size: 0.85rem;
  color: #555;
  cursor: pointer;
  transition: all 0.2s ease;
}

.category-filter:hover,
.difficulty-filter:hover {
  background-color: #f0f0f0;
}

.category-filter.active,
.difficulty-filter.active {
  background-color: #2b7de9;
  color: white;
  border-color: #2b7de9;
}

/* View Controls */
.view-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.results-count {
  font-size: 0.9rem;
  color: #666;
}

.view-toggle {
  display: flex;
}

.view-btn {
  background: none;
  border: 1px solid #e0e0e0;
  padding: 6px 12px;
  font-size: 0.9rem;
  color: #555;
  cursor: pointer;
  transition: all 0.2s ease;
}

.view-btn:first-child {
  border-radius: 6px 0 0 6px;
}

.view-btn:last-child {
  border-radius: 0 6px 6px 0;
}

.view-btn.active {
  background-color: #2b7de9;
  color: white;
  border-color: #2b7de9;
}

/* Featured Events */
.featured-events-section {
  margin-bottom: 30px;
}

.featured-events-section h2 {
  font-size: 1.5rem;
  margin-bottom: 20px;
  color: #333;
  position: relative;
  padding-left: 15px;
}

.featured-events-section h2::before {
  content: '';
  position: absolute;
  left: 0;
  top: 5px;
  height: 70%;
  width: 4px;
  background-color: #2b7de9;
  border-radius: 2px;
}

.featured-events-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 20px;
}

/* Events Grid View */
.events-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 20px;
  margin-bottom: 40px;
}

/* Event Card */
.event-card {
  background-color: white;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.event-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.event-image {
  position: relative;
  height: 180px;
}

.event-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.event-badges {
  position: absolute;
  bottom: 10px;
  left: 10px;
  display: flex;
  gap: 8px;
}

.event-type-badge {
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 0.75rem;
  font-weight: 600;
  text-transform: uppercase;
}

.featured-badge {
  background-color: rgba(255, 193, 7, 0.9);
  color: #333;
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 0.75rem;
  font-weight: 600;
}

.save-event-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: rgba(255, 255, 255, 0.9);
  border: none;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #666;
  font-size: 1rem;
  transition: all 0.2s ease;
  z-index: 2;
}

.save-event-btn:hover {
  color: #2b7de9;
  transform: scale(1.1);
}

.event-price {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: rgba(43, 125, 233, 0.9);
  color: white;
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 0.85rem;
  font-weight: 600;
}

.event-content {
  padding: 20px;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.event-title {
  font-size: 1.2rem;
  font-weight: 700;
  margin-bottom: 15px;
  color: #333;
  line-height: 1.4;
}

.event-meta {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  margin-bottom: 15px;
  font-size: 0.85rem;
  color: #666;
}

.event-date,
.event-duration,
.event-location {
  display: flex;
  align-items: center;
  gap: 5px;
}

.meta-icon {
  color: #2b7de9;
}

.event-host {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  font-size: 0.9rem;
}

.host-name {
  font-weight: 600;
  color: #333;
}

.host-title {
  color: #666;
}

.event-description {
  font-size: 0.95rem;
  line-height: 1.5;
  color: #555;
  margin-bottom: 20px;
  flex: 1;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.event-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: auto;
  margin-bottom: 15px;
}

.participants {
  display: flex;
  align-items: center;
  font-size: 0.85rem;
  color: #666;
}

.participants-icon {
  margin-right: 5px;
  color: #2b7de9;
}

.participant-count {
  font-weight: 600;
  color: #333;
}

.participant-label {
  color: #888;
}

.countdown {
  display: flex;
  gap: 8px;
}

.countdown-item {
  text-align: center;
  font-size: 0.8rem;
}

.count-value {
  font-weight: 700;
  color: #333;
  background-color: #f5f5f5;
  border-radius: 4px;
  padding: 2px 4px;
  min-width: 24px;
  display: inline-block;
}

.count-label {
  color: #888;
  font-size: 0.7rem;
}

.event-past {
  color: #f44336;
  font-size: 0.85rem;
  font-weight: 600;
}

.register-btn {
  width: 100%;
  background-color: #2b7de9;
  color: white;
  border: none;
  border-radius: 0 0 12px 12px;
  padding: 12px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.register-btn:hover {
  background-color: #1a68d3;
}

.register-btn.large {
  font-size: 1.1rem;
  padding: 15px;
  border-radius: 8px;
}

.difficulty-level {
  position: absolute;
  top: 150px;
  right: 10px;
  background-color: white;
  color: #333;
  font-size: 0.8rem;
  font-weight: 600;
  padding: 5px 10px;
  border-radius: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.difficulty-level[data-level="beginner"] {
  background-color: #4caf50;
  color: white;
}

.difficulty-level[data-level="intermediate"] {
  background-color: #ff9800;
  color: white;
}

.difficulty-level[data-level="advanced"] {
  background-color: #f44336;
  color: white;
}

.difficulty-level[data-level="all levels"] {
  background-color: #9e9e9e;
  color: white;
}

/* Events List View */
.events-list {
  margin-bottom: 40px;
}

.month-group {
  margin-bottom: 30px;
}

.month-header {
  font-size: 1.2rem;
  margin-bottom: 15px;
  color: #333;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 10px;
}

.month-events {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.list-event-item {
  display: flex;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
  overflow: hidden;
  transition: all 0.3s ease;
  cursor: pointer;
}

.list-event-item:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.list-event-date {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
  min-width: 70px;
  padding: 10px;
}

.event-day {
  font-size: 1.5rem;
  font-weight: 700;
  color: #333;
}

.event-weekday {
  font-size: 0.85rem;
  color: #666;
}

.list-event-content {
  flex: 1;
  padding: 15px;
}

.list-event-tags {
  display: flex;
  gap: 10px;
  margin-bottom: 8px;
}

.event-type-tag {
  font-size: 0.75rem;
  padding: 2px 8px;
  border-radius: 4px;
  text-transform: uppercase;
  font-weight: 600;
}

.event-type-tag.webinar {
  background-color: rgba(0, 204, 153, 0.15);
  color: #00cc99;
}

.event-type-tag.workshop {
  background-color: rgba(0, 102, 204, 0.15);
  color: #0066cc;
}

.event-type-tag.in-person {
  background-color: rgba(204, 102, 0, 0.15);
  color: #cc6600;
}

.free-tag {
  background-color: #4caf50;
  color: white;
  font-size: 0.75rem;
  padding: 2px 8px;
  border-radius: 4px;
  font-weight: 600;
}

.featured-tag {
  background-color: #ffc107;
  color: #333;
  font-size: 0.75rem;
  padding: 2px 8px;
  border-radius: 4px;
  font-weight: 600;
}

.list-event-title {
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 8px;
  color: #333;
}

.list-event-meta {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  font-size: 0.85rem;
  color: #666;
  margin-bottom: 5px;
}

.list-event-time,
.list-event-location,
.list-event-host {
  display: flex;
  align-items: center;
  gap: 5px;
}

.duration-span {
  color: #888;
}

.list-event-actions {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  padding: 0 15px;
  gap: 10px;
}

.list-event-price {
  background-color: #2b7de9;
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 0.85rem;
  font-weight: 600;
}

.mini-save-btn {
  background: none;
  border: none;
  color: #666;
  cursor: pointer;
  transition: all 0.2s ease;
  font-size: 1rem;
}

.mini-save-btn:hover {
  color: #2b7de9;
  transform: scale(1.1);
}

/* No Events */
.no-events {
  text-align: center;
  padding: 60px 20px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
}

.no-events h3 {
  font-size: 1.3rem;
  margin-bottom: 10px;
  color: #333;
}

.no-events p {
  color: #666;
  margin-bottom: 20px;
}

.reset-filters-btn {
  background-color: #2b7de9;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  font-weight: 600;
  cursor: pointer;
}

.reset-filters-btn:hover {
  background-color: #1a68d3;
}

/* Event Detail Modal */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  padding: 20px;
  overflow-y: auto;
}

.event-detail-modal {
  background-color: white;
  border-radius: 12px;
  width: 100%;
  max-width: 900px;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
  display: flex;
  flex-direction: column;
}

.modal-close {
  position: absolute;
  top: 15px;
  right: 15px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  cursor: pointer;
  z-index: 10;
}

.modal-close:hover {
  background-color: rgba(0, 0, 0, 0.7);
}

.modal-image {
  height: 250px;
  position: relative;
}

.modal-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.modal-price {
  position: absolute;
  top: 15px;
  left: 15px;
  font-size: 1rem;
}

.modal-content {
  padding: 30px;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 20px;
}

.modal-header h2 {
  font-size: 1.8rem;
  color: #333;
  margin: 0;
  flex: 1;
  margin-right: 20px;
}

.modal-save-btn {
  background: none;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  color: #666;
  transition: all 0.2s ease;
  white-space: nowrap;
}

.modal-save-btn:hover {
  background-color: #f5f5f5;
  color: #2b7de9;
}

.modal-meta {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 25px;
  font-size: 0.95rem;
  color: #666;
}

.meta-item {
  display: flex;
  align-items: center;
  gap: 8px;
}

.modal-host {
  margin-bottom: 25px;
}

.modal-host h3 {
  font-size: 1.1rem;
  color: #333;
  margin-bottom: 15px;
}

.host-info {
  display: flex;
  align-items: center;
  gap: 15px;
}

.host-avatar {
  width: 50px;
  height: 50px;
  background-color: #2b7de9;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.3rem;
  font-weight: 600;
}

.host-details {
  flex: 1;
}

.host-details .host-name {
  font-weight: 600;
  color: #333;
  font-size: 1rem;
  margin: 0 0 5px;
}

.host-details .host-title {
  color: #666;
  font-size: 0.9rem;
  margin: 0;
}

.modal-description {
  margin-bottom: 25px;
}

.modal-description h3 {
  font-size: 1.1rem;
  color: #333;
  margin-bottom: 15px;
}

.modal-description p {
  font-size: 0.95rem;
  line-height: 1.6;
  color: #555;
}

.modal-details {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  margin-bottom: 25px;
  padding: 20px;
  background-color: #f9f9fa;
  border-radius: 8px;
}

.detail-item h4 {
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 10px;
}

.detail-item p {
  font-size: 0.95rem;
  color: #333;
}

.difficulty-tag {
  display: inline-block;
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 0.85rem;
  font-weight: 600;
}

.difficulty-tag.beginner {
  background-color: #4caf50;
  color: white;
}

.difficulty-tag.intermediate {
  background-color: #ff9800;
  color: white;
}

.difficulty-tag.advanced {
  background-color: #f44336;
  color: white;
}

.difficulty-tag.all {
  background-color: #9e9e9e;
  color: white;
}

.tag-list {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.event-tag {
  background-color: #f0f4f8;
  color: #555;
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 0.85rem;
}

.modal-countdown {
  margin-bottom: 25px;
  text-align: center;
  padding: 20px;
  background-color: #f0f4f8;
  border-radius: 8px;
}

.modal-countdown h3 {
  font-size: 1.1rem;
  color: #333;
  margin-bottom: 15px;
}

.countdown.large {
  display: flex;
  justify-content: center;
  gap: 15px;
}

.countdown.large .countdown-item {
  text-align: center;
}

.countdown.large .count-value {
  font-size: 1.8rem;
  padding: 10px;
  min-width: 60px;
  border-radius: 8px;
  background-color: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
}

.countdown.large .count-label {
  font-size: 0.9rem;
  margin-top: 5px;
}

.event-past-notice {
  text-align: center;
  padding: 20px;
  background-color: #ffebee;
  border-radius: 8px;
  margin-bottom: 25px;
}

.event-past-notice h3 {
  font-size: 1.1rem;
  color: #d32f2f;
  margin-bottom: 10px;
}

.event-past-notice p {
  color: #555;
}

.modal-actions {
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
}

.add-calendar-btn {
  background-color: #f0f4f8;
  color: #555;
  border: none;
  border-radius: 8px;
  padding: 15px;
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
}

.add-calendar-btn:hover {
  background-color: #e0e0e0;
}

/* Events Footer */
.events-footer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
  margin-top: 60px;
  margin-bottom: 30px;
}

.suggest-event,
.event-calendar {
  background-color: white;
  border-radius: 12px;
  padding: 30px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  text-align: center;
}

.suggest-event h3,
.event-calendar h3 {
  font-size: 1.3rem;
  color: #333;
  margin-bottom: 15px;
}

.suggest-event p,
.event-calendar p {
  color: #666;
  margin-bottom: 20px;
  font-size: 0.95rem;
}

.suggest-btn,
.calendar-btn {
  background-color: #2b7de9;
  color: white;
  border: none;
  border-radius: 6px;
  padding: 12px 20px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
  display: inline-flex;
  align-items: center;
  gap: 8px;
}

.suggest-btn:hover,
.calendar-btn:hover {
  background-color: #1a68d3;
  transform: translateY(-2px);
}

.calendar-icon {
  font-size: 1.1rem;
}

.calendar-info {
  font-size: 0.85rem;
  color: #888;
  margin-top: 10px;
}

/* Modal Open Body Style */
body.modal-open {
  overflow: hidden;
}

/* Responsive Styles */
@media (max-width: 992px) {
  .events-grid,
  .featured-events-container {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }
  
  .modal-image {
    height: 200px;
  }
}

@media (max-width: 768px) {
  .page-header {
    padding: 30px 20px;
  }
  
  .page-header h1 {
    font-size: 2rem;
  }
  
  .main-filters {
    flex-direction: column;
    gap: 15px;
  }
  
  .filter-toggles {
    flex-wrap: wrap;
  }
  
  .events-grid,
  .featured-events-container {
    grid-template-columns: 1fr;
  }
  
  .view-controls {
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
  }
  
  .modal-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
  }
  
  .modal-meta {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
  
  .modal-actions {
    flex-direction: column;
  }
  
  .add-calendar-btn {
    width: 100%;
    justify-content: center;
  }
  
  .list-event-item {
    flex-direction: column;
  }
  
  .list-event-date {
    flex-direction: row;
    width: 100%;
    padding: 10px 15px;
    gap: 10px;
    justify-content: flex-start;
  }
  
  .event-day {
    font-size: 1.2rem;
  }
  
  .list-event-actions {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding: 0 15px 15px;
  }
}

@media (max-width: 576px) {
  .page-header h1 {
    font-size: 1.7rem;
  }
  
  .page-header p {
    font-size: 0.95rem;
  }
  
  .event-image {
    height: 160px;
  }
  
  .event-badges {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .event-type-badge,
  .featured-badge {
    font-size: 0.7rem;
    padding: 3px 8px;
  }
  
  .event-title {
    font-size: 1.1rem;
  }
  
  .event-meta {
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;
  }
  
  .event-footer {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
  
  .countdown {
    align-self: flex-end;
  }
  
  .countdown.large .count-value {
    font-size: 1.5rem;
    min-width: 45px;
    padding: 8px;
  }
  
  .countdown.large .count-label {
    font-size: 0.8rem;
  }
  
  .modal-details {
    grid-template-columns: 1fr;
  }
  
  .modal-content {
    padding: 20px 15px;
  }
  
  .event-title,
  .modal-header h2 {
    font-size: 1.3rem;
  }
  
  .list-event-meta {
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
  }
  
  .list-event-tags {
    flex-wrap: wrap;
  }
}

@media (max-width: 400px) {
  .countdown-item {
    min-width: auto;
  }
  
  .count-value {
    font-size: 0.9rem;
    min-width: 20px;
    padding: 2px 4px;
  }
  
  .count-label {
    font-size: 0.6rem;
  }
  
  .featured-events-section h2,
  .month-header {
    font-size: 1.2rem;
  }
  
  .event-badges,
  .event-price,
  .save-event-btn {
    transform: scale(0.9);
  }
}/* events.css - Complete styling for events page */

/* Main Container */
.events-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

/* Page Header */
.page-header {
  background: linear-gradient(135deg, #1a237e 0%, #2b7de9 100%);
  color: white;
  border-radius: 12px;
  padding: 40px 30px;
  margin-bottom: 30px;
  text-align: center;
}

.header-content {
  max-width: 800px;
  margin: 0 auto;
}

.page-header h1 {
  font-size: 2.5rem;
  margin-bottom: 15px;
  font-weight: 700;
}

.page-header p {
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 10px;
  opacity: 0.9;
}

/* Page Content */
.page-content {
  position: relative;
}

/* Search Bar */
.search-container {
  margin-bottom: 20px;
}

.search-bar {
  display: flex;
  align-items: center;
  background-color: white;
  border-radius: 8px;
  padding: 10px 15px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.search-icon {
  color: #666;
  margin-right: 10px;
  font-size: 16px;
}

.search-bar input {
  flex: 1;
  border: none;
  padding: 8px 0;
  font-size: 1rem;
  outline: none;
}

.clear-search {
  background: none;
  border: none;
  color: #666;
  font-size: 1.5rem;
  cursor: pointer;
  padding: 0 5px;
}

.clear-search:hover {
  color: #333;
}

/* Filters */
.filters-container {
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  margin-bottom: 20px;
  overflow: hidden;
}

.main-filters {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 15px;
  border-bottom: 1px solid #eee;
}

.type-filters {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.filter-btn {
  background: none;
  border: 1px solid #e0e0e0;
  border-radius: 20px;
  padding: 8px 15px;
  font-size: 0.9rem;
  color: #555;
  cursor: pointer;
  transition: all 0.2s ease;
}

.filter-btn:hover {
  background-color: #f5f5f5;
}

.filter-btn.active {
  background-color: #2b7de9;
  color: white;
  border-color: #2b7de9;
}

.filter-toggles {
  display: flex;
  align-items: center;
  gap: 15px;
}

.advanced-filter-btn {
  background: none;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  padding: 8px 12px;
  font-size: 0.9rem;
  color: #555;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: all 0.2s ease;
}

.advanced-filter-btn:hover,
.advanced-filter-btn.active {
  background-color: #f5f5f5;
}

.toggle-label {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  font-size: 0.9rem;
  color: #555;
}

.toggle-label input {
  cursor: pointer;
}

.toggle-text {
  user-select: none;
}

.advanced-filters {
  background-color: #f9f9fa;
  padding: 15px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  overflow: hidden;
}

.filter-group {
  flex: 1;
  min-width: 250px;
}

.filter-group h4 {
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 10px;
}

.filter-options {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.category-filter,
.difficulty-filter {
  background-color: white;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  padding: 6px 12px;
  font-size: 0.85rem;
  color: #555;
  cursor: pointer;
  transition: all 0.2s ease;
}

.category-filter:hover,
.difficulty-filter:hover {
  background-color: #f0f0f0;
}

.category-filter.active,
.difficulty-filter.active {
  background-color: #2b7de9;
  color: white;
  border-color: #2b7de9;
}

/* View Controls */
.view-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.results-count {
  font-size: 0.9rem;
  color: #666;
}

.view-toggle {
  display: flex;
}

.view-btn {
  background: none;
  border: 1px solid #e0e0e0;
  padding: 6px 12px;
  font-size: 0.9rem;
  color: #555;
  cursor: pointer;
  transition: all 0.2s ease;
}

.view-btn:first-child {
  border-radius: 6px 0 0 6px;
}

.view-btn:last-child {
  border-radius: 0 6px 6px 0;
}

.view-btn.active {
  background-color: #2b7de9;
  color: white;
  border-color: #2b7de9;
}

/* Featured Events */
.featured-events-section {
  margin-bottom: 30px;
}

.featured-events-section h2 {
  font-size: 1.5rem;
  margin-bottom: 20px;
  color: #333;
  position: relative;
  padding-left: 15px;
}

.featured-events-section h2::before {
  content: '';
  position: absolute;
  left: 0;
  top: 5px;
  height: 70%;
  width: 4px;
  background-color: #2b7de9;
  border-radius: 2px;
}

.featured-events-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 20px;
}

/* Events Grid View */
.events-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 20px;
  margin-bottom: 40px;
}

/* Event Card */
.event-card {
  background-color: white;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.event-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.event-image {
  position: relative;
  height: 180px;
}

.event-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.event-badges {
  position: absolute;
  bottom: 10px;
  left: 10px;
  display: flex;
  gap: 8px;
}

.event-type-badge {
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 0.75rem;
  font-weight: 600;
  text-transform: uppercase;
}

.featured-badge {
  background-color: rgba(255, 193, 7, 0.9);
  color: #333;
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 0.75rem;
  font-weight: 600;
}

.save-event-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: rgba(255, 255, 255, 0.9);
  border: none;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #666;
  font-size: 1rem;
  transition: all 0.2s ease;
  z-index: 2;
}

.save-event-btn:hover {
  color: #2b7de9;
  transform: scale(1.1);
}

.event-price {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: rgba(43, 125, 233, 0.9);
  color: white;
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 0.85rem;
  font-weight: 600;
}

.event-content {
  padding: 20px;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.event-title {
  font-size: 1.2rem;
  font-weight: 700;
  margin-bottom: 15px;
  color: #333;
  line-height: 1.4;
}

.event-meta {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  margin-bottom: 15px;
  font-size: 0.85rem;
  color: #666;
}

.event-date,
.event-duration,
.event-location {
  display: flex;
  align-items: center;
  gap: 5px;
}

.meta-icon {
  color: #2b7de9;
}

.event-host {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  font-size: 0.9rem;
}

.host-name {
  font-weight: 600;
  color: #333;
}

.host-title {
  color: #666;
}

.event-description {
  font-size: 0.95rem;
  line-height: 1.5;
  color: #555;
  margin-bottom: 20px;
  flex: 1;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.event-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: auto;
  margin-bottom: 15px;
}

.participants {
  display: flex;
  align-items: center;
  font-size: 0.85rem;
  color: #666;
}

.participants-icon {
  margin-right: 5px;
  color: #2b7de9;
}

.participant-count {
  font-weight: 600;
  color: #333;
}

.participant-label {
  color: #888;
}

.countdown {
  display: flex;
  gap: 8px;
}

.countdown-item {
  text-align: center;
  font-size: 0.8rem;
}

.count-value {
  font-weight: 700;
  color: #333;
  background-color: #f5f5f5;
  border-radius: 4px;
  padding: 2px 4px;
  min-width: 24px;
  display: inline-block;
}

.count-label {
  color: #888;
  font-size: 0.7rem;
}

.event-past {
  color: #f44336;
  font-size: 0.85rem;
  font-weight: 600;
}

.register-btn {
  width: 100%;
  background-color: #2b7de9;
  color: white;
  border: none;
  border-radius: 0 0 12px 12px;
  padding: 12px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.register-btn:hover {
  background-color: #1a68d3;
}

.register-btn.large {
  font-size: 1.1rem;
  padding: 15px;
  border-radius: 8px;
}

.difficulty-level {
  position: absolute;
  top: 150px;
  right: 10px;
  background-color: white;
  color: #333;
  font-size: 0.8rem;
  font-weight: 600;
  padding: 5px 10px;
  border-radius: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.difficulty-level[data-level="beginner"] {
  background-color: #4caf50;
  color: white;
}

.difficulty-level[data-level="intermediate"] {
  background-color: #ff9800;
  color: white;
}

.difficulty-level[data-level="advanced"] {
  background-color: #f44336;
  color: white;
}

.difficulty-level[data-level="all levels"] {
  background-color: #9e9e9e;
  color: white;
}

/* Events List View */
.events-list {
  margin-bottom: 40px;
}

.month-group {
  margin-bottom: 30px;
}

.month-header {
  font-size: 1.2rem;
  margin-bottom: 15px;
  color: #333;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 10px;
}

.month-events {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.list-event-item {
  display: flex;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
  overflow: hidden;
  transition: all 0.3s ease;
  cursor: pointer;
}

.list-event-item:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.list-event-date {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
  min-width: 70px;
  padding: 10px;
}

.event-day {
  font-size: 1.5rem;
  font-weight: 700;
  color: #333;
}

.event-weekday {
  font-size: 0.85rem;
  color: #666;
}

.list-event-content {
  flex: 1;
  padding: 15px;
}

.list-event-tags {
  display: flex;
  gap: 10px;
  margin-bottom: 8px;
}

.event-type-tag {
  font-size: 0.75rem;
  padding: 2px 8px;
  border-radius: 4px;
  text-transform: uppercase;
  font-weight: 600;
}

.event-type-tag.webinar {
  background-color: rgba(0, 204, 153, 0.15);
  color: #00cc99;
}

.event-type-tag.workshop {
  background-color: rgba(0, 102, 204, 0.15);
  color: #0066cc;
}

.event-type-tag.in-person {
  background-color: rgba(204, 102, 0, 0.15);
  color: #cc6600;
}

.free-tag {
  background-color: #4caf50;
  color: white;
  font-size: 0.75rem;
  padding: 2px 8px;
  border-radius: 4px;
  font-weight: 600;
}

.featured-tag {
  background-color: #ffc107;
  color: #333;
  font-size: 0.75rem;
  padding: 2px 8px;
  border-radius: 4px;
  font-weight: 600;
}

.list-event-title {
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 8px;
  color: #333;
}

.list-event-meta {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  font-size: 0.85rem;
  color: #666;
  margin-bottom: 5px;
}

.list-event-time,
.list-event-location,
.list-event-host {
  display: flex;
  align-items: center;
  gap: 5px;
}

.duration-span {
  color: #888;
}

.list-event-actions {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  padding: 0 15px;
  gap: 10px;
}

.list-event-price {
  background-color: #2b7de9;
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 0.85rem;
  font-weight: 600;
}

.mini-save-btn {
  background: none;
  border: none;
  color: #666;
  cursor: pointer;
  transition: all 0.2s ease;
  font-size: 1rem;
}

.mini-save-btn:hover {
  color: #2b7de9;
  transform: scale(1.1);
}

/* No Events */
.no-events {
  text-align: center;
  padding: 60px 20px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
}

.no-events h3 {
  font-size: 1.3rem;
  margin-bottom: 10px;
  color: #333;
}

.no-events p {
  color: #666;
  margin-bottom: 20px;
}

.reset-filters-btn {
  background-color: #2b7de9;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  font-weight: 600;
  cursor: pointer;
}

.reset-filters-btn:hover {
  background-color: #1a68d3;
}

/* Event Detail Modal */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  padding: 20px;
  overflow-y: auto;
}

.event-detail-modal {
  background-color: white;
  border-radius: 12px;
  width: 100%;
  max-width: 900px;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
  display: flex;
  flex-direction: column;
}

.modal-close {
  position: absolute;
  top: 15px;
  right: 15px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  cursor: pointer;
  z-index: 10;
}

.modal-close:hover {
  background-color: rgba(0, 0, 0, 0.7);
}

.modal-image {
  height: 250px;
  position: relative;
}

.modal-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.modal-price {
  position: absolute;
  top: 15px;
  left: 15px;
  font-size: 1rem;
}

.modal-content {
  padding: 30px;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 20px;
}

.modal-header h2 {
  font-size: 1.8rem;
  color: #333;
  margin: 0;
  flex: 1;
  margin-right: 20px;
}

.modal-save-btn {
  background: none;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  color: #666;
  transition: all 0.2s ease;
  white-space: nowrap;
}

.modal-save-btn:hover {
  background-color: #f5f5f5;
  color: #2b7de9;
}

.modal-meta {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 25px;
  font-size: 0.95rem;
  color: #666;
}

.meta-item {
  display: flex;
  align-items: center;
  gap: 8px;
}

.modal-host {
  margin-bottom: 25px;
}

.modal-host h3 {
  font-size: 1.1rem;
  color: #333;
  margin-bottom: 15px;
}

.host-info {
  display: flex;
  align-items: center;
  gap: 15px;
}

.host-avatar {
  width: 50px;
  height: 50px;
  background-color: #2b7de9;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.3rem;
  font-weight: 600;
}

.host-details {
  flex: 1;
}

.host-details .host-name {
  font-weight: 600;
  color: #333;
  font-size: 1rem;
  margin: 0 0 5px;
}

.host-details .host-title {
  color: #666;
  font-size: 0.9rem;
  margin: 0;
}

.modal-description {
  margin-bottom: 25px;
}

.modal-description h3 {
  font-size: 1.1rem;
  color: #333;
  margin-bottom: 15px;
}

.modal-description p {
  font-size: 0.95rem;
  line-height: 1.6;
  color: #555;
}

.modal-details {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  margin-bottom: 25px;
  padding: 20px;
  background-color: #f9f9fa;
  border-radius: 8px;
}

.detail-item h4 {
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 10px;
}

.detail-item p {
  font-size: 0.95rem;
  color: #333;
}

.difficulty-tag {
  display: inline-block;
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 0.85rem;
  font-weight: 600;
}

.difficulty-tag.beginner {
  background-color: #4caf50;
  color: white;
}

.difficulty-tag.intermediate {
  background-color: #ff9800;
  color: white;
}

.difficulty-tag.advanced {
  background-color: #f44336;
  color: white;
}

.difficulty-tag.all {
  background-color: #9e9e9e;
  color: white;
}

.tag-list {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.event-tag {
  background-color: #f0f4f8;
  color: #555;
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 0.85rem;
}

.modal-countdown {
  margin-bottom: 25px;
  text-align: center;
  padding: 20px;
  background-color: #f0f4f8;
  border-radius: 8px;
}

.modal-countdown h3 {
  font-size: 1.1rem;
  color: #333;
  margin-bottom: 15px;
}

.countdown.large {
  display: flex;
  justify-content: center;
  gap: 15px;
}

.countdown.large .countdown-item {
  text-align: center;
}

.countdown.large .count-value {
  font-size: 1.8rem;
  padding: 10px;
  min-width: 60px;
  border-radius: 8px;
  background-color: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
}

.countdown.large .count-label {
  font-size: 0.9rem;
  margin-top: 5px;
}

.event-past-notice {
  text-align: center;
  padding: 20px;
  background-color: #ffebee;
  border-radius: 8px;
  margin-bottom: 25px;
}

.event-past-notice h3 {
  font-size: 1.1rem;
  color: #d32f2f;
  margin-bottom: 10px;
}

.event-past-notice p {
  color: #555;
}

.modal-actions {
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
}

.add-calendar-btn {
  background-color: #f0f4f8;
  color: #555;
  border: none;
  border-radius: 8px;
  padding: 15px;
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
}

.add-calendar-btn:hover {
  background-color: #e0e0e0;
}

/* Events Footer */
.events-footer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
  margin-top: 60px;
  margin-bottom: 30px;
}

.suggest-event,
.event-calendar {
  background-color: white;
  border-radius: 12px;
  padding: 30px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  text-align: center;
}

.suggest-event h3,
.event-calendar h3 {
  font-size: 1.3rem;
  color: #333;
  margin-bottom: 15px;
}

.suggest-event p,
.event-calendar p {
  color: #666;
  margin-bottom: 20px;
  font-size: 0.95rem;
}

.suggest-btn,
.calendar-btn {
  background-color: #2b7de9;
  color: white;
  border: none;
  border-radius: 6px;
  padding: 12px 20px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
  display: inline-flex;
  align-items: center;
  gap: 8px;
}

.suggest-btn:hover,
.calendar-btn:hover {
  background-color: #1a68d3;
  transform: translateY(-2px);
}

.calendar-icon {
  font-size: 1.1rem;
}

.calendar-info {
  font-size: 0.85rem;
  color: #888;
  margin-top: 10px;
}

/* Modal Open Body Style */
body.modal-open {
  overflow: hidden;
}

/* Responsive Styles */
@media (max-width: 992px) {
  .events-grid,
  .featured-events-container {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }
  
  .modal-image {
    height: 200px;
  }
}

@media (max-width: 768px) {
  .page-header {
    padding: 30px 20px;
  }
  
  .page-header h1 {
    font-size: 2rem;
  }
  
  .main-filters {
    flex-direction: column;
    gap: 15px;
  }
  
  .filter-toggles {
    flex-wrap: wrap;
  }
  }