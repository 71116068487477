/* Main Variables */
:root {
  --color-primary: #2b7de9;
  --color-primary-light: rgba(43, 125, 233, 0.1);
  --color-primary-dark: #1e63c4;
  --color-secondary: #ff5252;
  --color-secondary-dark: #e53935;
  --color-text: #333333;
  --color-text-light: #666666;
  --color-text-lighter: #888888;
  --color-border: #e0e0e0;
  --color-background: #f8f9fa;
  --color-white: #ffffff;
  --color-success: #4caf50;
  --color-warning: #ff9800;
  --color-error: #f44336;
  --border-radius: 8px;
  --box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  --transition: all 0.3s ease;
}

.dashboard {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: var(--color-background);
  color: var(--color-text);
  font-family: 'Poppins', sans-serif;
}

/* Loading State */
.dashboard.loading-state {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
  gap: 20px;
}

.loader {
  width: 48px;
  height: 48px;
  border: 5px solid var(--color-primary-light);
  border-bottom-color: var(--color-primary);
  border-radius: 50%;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Header */
.dashboard-header {
  background-color: var(--color-white);
  padding: 16px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  position: sticky;
  top: 0;
  z-index: 100;
}

.header-left {
  display: flex;
  align-items: center;
  gap: 16px;
}

.logo {
  height: 40px;
  width: auto;
}

.header-left h1 {
  font-size: 1.3rem;
  font-weight: 600;
  color: var(--color-primary);
  margin: 0;
}

.header-right {
  display: flex;
  align-items: center;
  gap: 20px;
}

.subscription-indicator {
  display: flex;
  align-items: center;
  padding: 6px 12px;
  border-radius: 20px;
  color: white;
  font-size: 0.85rem;
  font-weight: 500;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.status-text {
  margin-right: 5px;
}

.days-remaining {
  background-color: rgba(255, 255, 255, 0.25);
  padding: 2px 8px;
  border-radius: 10px;
  font-size: 0.75rem;
}

/* Profile Section */
.profile-container {
  position: relative;
}

.profile-btn {
  display: flex;
  align-items: center;
  gap: 10px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 8px 12px;
  border-radius: var(--border-radius);
  transition: var(--transition);
}

.profile-btn:hover {
  background-color: var(--color-primary-light);
}

.avatar {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: var(--color-primary);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 1rem;
}

.avatar.large {
  width: 64px;
  height: 64px;
  font-size: 1.5rem;
}

.username {
  font-weight: 500;
  color: var(--color-text);
}

.profile-menu {
  position: absolute;
  top: calc(100% + 10px);
  right: 0;
  background-color: var(--color-white);
  border-radius: var(--border-radius);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  width: 280px;
  overflow: hidden;
  z-index: 200;
}

.profile-header {
  padding: 20px;
  display: flex;
  align-items: center;
  gap: 16px;
  border-bottom: 1px solid var(--color-border);
}

.user-details {
  overflow: hidden;
}

.user-name {
  font-weight: 600;
  margin: 0 0 4px;
  color: var(--color-text);
}

.user-email {
  font-size: 0.85rem;
  margin: 0;
  color: var(--color-text-light);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.menu-options {
  padding: 8px;
}

.menu-item {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 12px 16px;
  width: 100%;
  text-align: left;
  background: none;
  border: none;
  cursor: pointer;
  color: var(--color-text);
  border-radius: 6px;
  transition: var(--transition);
  font-size: 0.95rem;
}

.menu-item:hover {
  background-color: var(--color-primary-light);
  color: var(--color-primary);
}

.menu-item.logout {
  color: var(--color-secondary);
}

.menu-item.logout:hover {
  background-color: rgba(255, 82, 82, 0.1);
  color: var(--color-secondary-dark);
}

/* Admin Panel Styles */
.admin-toggle {
  background-color: var(--color-primary);
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-right: 15px;
}

.admin-toggle:hover {
  background-color: var(--color-primary-dark);
}

.admin-toggle.active {
  background-color: var(--color-text);
}

.admin-panel {
  display: flex;
  width: 100%;
  height: calc(100vh - 130px);
}

.admin-sidebar {
  width: 220px;
  background-color: var(--color-white);
  border-right: 1px solid var(--color-border);
  padding: 20px 0;
}

.admin-sidebar h3 {
  padding: 0 20px;
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 1.1rem;
}

.admin-menu {
  list-style: none;
  padding: 0;
  margin: 0;
}

.admin-menu li {
  padding: 12px 20px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.admin-menu li:hover {
  background-color: var(--color-primary-light);
  color: var(--color-primary);
}

.admin-menu li.active {
  background-color: var(--color-primary-light);
  color: var(--color-primary);
  border-left: 3px solid var(--color-primary);
}

.admin-content {
  flex: 1;
  padding: 20px;
  overflow-y: auto;
}

.admin-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.admin-header h2 {
  margin: 0;
  font-size: 1.5rem;
  color: var(--color-text);
}

.user-table-container {
  background-color: var(--color-white);
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.user-table {
  width: 100%;
  border-collapse: collapse;
}

.user-table th,
.user-table td {
  padding: 14px 20px;
  text-align: left;
  border-bottom: 1px solid var(--color-border);
}

.user-table th {
  background-color: var(--color-background);
  font-weight: 600;
  color: var(--color-text);
}

.user-table tr:last-child td {
  border-bottom: none;
}

.user-table tr:hover td {
  background-color: var(--color-primary-light);
}

.user-table tr.expired td {
  background-color: rgba(244, 67, 54, 0.05);
}

.status {
  padding: 4px 10px;
  border-radius: 20px;
  font-size: 0.8rem;
  font-weight: 500;
  display: inline-block;
}

.status.active {
  background-color: rgba(76, 175, 80, 0.15);
  color: var(--color-success);
}

.status.expired {
  background-color: rgba(244, 67, 54, 0.15);
  color: var(--color-error);
}

.status.none,
.status.inactive {
  background-color: rgba(255, 152, 0, 0.15);
  color: var(--color-warning);
}

.device-count {
  padding: 4px 10px;
  border-radius: 20px;
  font-size: 0.8rem;
  font-weight: 500;
}

.device-count.max {
  background-color: rgba(244, 67, 54, 0.15);
  color: var(--color-error);
}

.actions {
  width: 180px;
}

.add-subscription-btn {
  background-color: var(--color-primary);
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9rem;
  transition: all 0.2s ease;
}

.add-subscription-btn:hover {
  background-color: var(--color-primary-dark);
}

/* Main Content Area */
.dashboard-main {
  display: flex;
  flex: 1;
  height: calc(100vh - 130px);
}

/* Sidebar */
.sidebar {
  width: 350px;
  background-color: var(--color-white);
  border-right: 1px solid var(--color-border);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
}

.sidebar-header {
  padding: 20px;
  border-bottom: 1px solid var(--color-border);
}

.sidebar-header h3 {
  margin: 0;
  color: var(--color-text);
  font-size: 1.1rem;
  font-weight: 600;
}

.playlist-container {
  overflow-y: auto;
  flex: 1;
  padding: 10px;
}

.playlist {
  margin-bottom: 10px;
  border-radius: var(--border-radius);
  background-color: var(--color-white);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  overflow: hidden;
  transition: var(--transition);
}

.playlist.expanded {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.playlist-header {
  padding: 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 12px;
  transition: var(--transition);
}

.playlist-header:hover {
  background-color: var(--color-primary-light);
}

.playlist-icon {
  font-size: 1.5rem;
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.playlist-title-container {
  flex: 1;
  overflow: hidden;
}

.playlist-title {
  margin: 0;
  font-size: 1rem;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--color-text);
}

.playlist-description {
  margin: 4px 0 0;
  font-size: 0.8rem;
  color: var(--color-text-lighter);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.playlist-toggle {
  color: var(--color-text-lighter);
  transition: var(--transition);
}

.playlist.expanded .playlist-toggle {
  color: var(--color-primary);
}

.playlist-content {
  padding: 0 0 10px;
  background-color: rgba(0, 0, 0, 0.02);
}

.section {
  margin: 5px 0;
}

.section-header {
  padding: 10px 15px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: var(--transition);
}

.section-header:hover {
  background-color: rgba(0, 0, 0, 0.03);
}

.section-title {
  margin: 0;
  font-size: 0.9rem;
  font-weight: 500;
  color: var(--color-text);
}

.section-toggle {
  color: var(--color-text-lighter);
  font-size: 0.8rem;
}

.video-list {
  padding: 5px 15px 5px 20px;
}

.video-item {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 8px 10px;
  border-radius: 6px;
  cursor: pointer;
  transition: var(--transition);
  margin-bottom: 5px;
}

.video-item:hover:not(.locked) {
  background-color: var(--color-primary-light);
}

.video-item.selected {
  background-color: var(--color-primary-light);
  border-left: 3px solid var(--color-primary);
}

.video-item.locked {
  opacity: 0.7;
  cursor: not-allowed;
}

.video-icon {
  color: var(--color-primary);
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.video-item.locked .video-icon {
  color: var(--color-text-lighter);
}

.video-details {
  flex: 1;
}

.video-title {
  margin: 0;
  font-size: 0.85rem;
  color: var(--color-text);
}

.video-duration {
  margin: 2px 0 0;
  font-size: 0.75rem;
  color: var(--color-text-lighter);
}

.empty-state {
  padding: 40px 20px;
  text-align: center;
  color: var(--color-text-light);
}

/* Video Section */
.video-section {
  flex: 1;
  padding: 30px;
  overflow-y: auto;
}

.video-player-container {
  max-width: 1000px;
  margin: 0 auto;
}

.video-player {
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
  position: relative;
  background-color: #000;
  border-radius: var(--border-radius);
  overflow: hidden;
  margin-bottom: 24px;
}

.video-placeholder {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: rgba(255, 255, 255, 0.5);
}

.play-icon {
  font-size: 3rem;
  margin-bottom: 16px;
}

.video-info {
  padding: 20px 0;
}

.video-meta {
  margin-bottom: 16px;
}

.video-title {
  margin: 0 0 8px;
  font-size: 1rem;
  font-weight: 600;
  color: var(--color-text);
}

.video-path {
  margin: 0;
  font-size: 0.9rem;
  color: var(--color-text-light);
}

.video-description {
  margin: 0;
  font-size: 1rem;
  line-height: 1.6;
  color: var(--color-text);
}

/* Welcome Screen */
.welcome-screen {
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
}

.welcome-screen h2 {
  margin-bottom: 16px;
  font-size: 2rem;
  color: var(--color-primary);
}

.welcome-message {
  font-size: 1.1rem;
  margin-bottom: 40px;
  color: var(--color-text-light);
}

.subscription-banner {
  background-color: var(--color-primary-light);
  padding: 30px;
  border-radius: var(--border-radius);
  margin-bottom: 40px;
  text-align: center;
}

.subscription-banner h3 {
  color: var(--color-primary);
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 1.3rem;
}

.subscription-banner p {
  margin-bottom: 20px;
  color: var(--color-text);
}

.payment-options {
  display: flex;
  justify-content: center;
  margin: 30px 0;
}

.payment-option {
  background-color: white;
  border-radius: 8px;
  padding: 24px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 100%;
  max-width: 300px;
  position: relative;
  border: 2px solid var(--color-primary);
}

.payment-option h4 {
  margin-top: 0;
  color: var(--color-text);
  font-size: 1.2rem;
}

.price {
  font-size: 2rem;
  font-weight: 700;
  color: var(--color-primary);
  margin: 15px 0 5px;
}

.duration {
  color: var(--color-text-light);
  margin-bottom: 0;
}

.payment-instructions {
  background-color: white;
  border-radius: 8px;
  padding: 25px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  margin-top: 30px;
}

.payment-instructions h4 {
  margin-top: 0;
  margin-bottom: 15px;
  color: var(--color-text);
}

.payment-instructions ol {
  padding-left: 20px;
  margin-bottom: 25px;
}

.payment-instructions li {
  margin-bottom: 15px;
}

.reference-box {
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: #f5f5f5;
  padding: 10px 15px;
  border-radius: 6px;
  margin-top: 8px;
  font-family: monospace;
  font-size: 1.1rem;
}

.copy-btn {
  background: none;
  border: none;
  color: var(--color-primary);
  cursor: pointer;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.copy-btn:hover {
  color: var(--color-primary-dark);
}

.bank-details {
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 6px;
}

.bank-details h5 {
  margin-top: 0;
  margin-bottom: 15px;
  color: var(--color-text);
}

.bank-details p {
  margin: 8px 0;
}

.get-started-guide {
  background-color: white;
  padding: 30px;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
}

.get-started-guide h3 {
  margin-top: 0;
  margin-bottom: 20px;
  color: var(--color-text);
  font-size: 1.3rem;
}

.guide-steps {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 20px;
}

.guide-step {
  display: flex;
  align-items: center;
  gap: 16px;
}

.step-number {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: var(--color-primary);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
}

.guide-step p {
  margin: 0;
  font-size: 1rem;
  color: var(--color-text);
}

/* Device Management */
.device-management {
  margin-top: 40px;
  background-color: white;
  padding: 25px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.device-management h3 {
  margin-top: 0;
  margin-bottom: 15px;
}

.device-list {
  margin-top: 20px;
}

.device-item {
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 15px;
  border-radius: 8px;
  background-color: #f5f5f5;
  margin-bottom: 12px;
  position: relative;
}

.device-item.current {
  border: 1px solid var(--color-primary);
  background-color: var(--color-primary-light);
}

.device-icon {
  width: 40px;
  height: 40px;
  background-color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-text);
  font-size: 1.2rem;
}

.device-details {
  flex: 1;
}

.device-name {
  margin: 0 0 4px;
  font-weight: 500;
}

.last-used {
  margin: 0;
  font-size: 0.85rem;
  color: var(--color-text-light);
}

.remove-device-btn {
  background-color: rgba(244, 67, 54, 0.1);
  color: var(--color-error);
  border: none;
  padding: 6px 12px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.85rem;
  transition: all 0.2s ease;
}

.remove-device-btn:hover {
  background-color: rgba(244, 67, 54, 0.2);
}

.current-device-badge {
  position: absolute;
  top: -10px;
  right: 15px;
  background-color: var(--color-primary);
  color: white;
  padding: 4px 10px;
  border-radius: 20px;
  font-size: 0.75rem;
  font-weight: 500;
}

.device-limit-warning {
  display: flex;
  align-items: center;
  gap: 12px;
  background-color: rgba(255, 152, 0, 0.1);
  padding: 15px;
  border-radius: 6px;
  margin-top: 20px;
  color: var(--color-warning);
}

.device-limit-warning p {
  margin: 0;
}

/* Payment Modal */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.payment-modal {
  background-color: white;
  border-radius: 8px;
  width: 450px;
  max-width: 90%;
  padding: 25px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);
}

.payment-modal h2 {
  margin-top: 0;
  color: var(--color-text);
  margin-bottom: 15px;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
}

.subscription-details {
  background-color: var(--color-primary-light);
  padding: 15px;
  border-radius: 6px;
  margin-top: 10px;
}

.subscription-details p {
  margin: 5px 0;
}

.modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 25px;
}

.cancel-btn {
  background-color: #f5f5f5;
  color: var(--color-text);
  border: none;
  padding: 10px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.2s ease;
}

.cancel-btn:hover {
  background-color: #e0e0e0;
}

.confirm-btn {
  background-color: var(--color-primary);
  color: white;
  border: none;
  padding: 10px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.2s ease;
}

.confirm-btn:hover {
  background-color: var(--color-primary-dark);
}

/* Footer */
.dashboard-footer {
  background-color: var(--color-white);
  padding: 16px 24px;
  text-align: center;
  border-top: 1px solid var(--color-border);
  font-size: 0.9rem;
  color: var(--color-text-light);
}

/* Responsive Adjustments */
@media (max-width: 1200px) {
  .dashboard-main {
    flex-direction: column;
    height: auto;
  }
  
  .sidebar {
    width: 100%;
    border-right: none;
    border-bottom: 1px solid var(--color-border);
    max-height: 400px;
  }
  
  .video-section {
    padding: 20px;
  }
  
  .admin-panel {
    flex-direction: column;
    height: auto;
  }
  
  .admin-sidebar {
    width: 100%;
    border-right: none;
    border-bottom: 1px solid var(--color-border);
  }
}

@media (max-width: 768px) {
  .dashboard-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    padding: 16px;
  }
  
  .header-right {
    width: 100%;
    justify-content: space-between;
  }
  
  .sidebar {
    max-height: 300px;
  }
  
  .video-section {
    padding: 16px;
  }
  
  .welcome-screen h2 {
    font-size: 1.5rem;
  }
  
  .guide-steps {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .payment-options {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
}

/* Animations */
@keyframes fadeIn {
  from { opacity: 0; transform: translateY(10px); }
  to { opacity: 1; transform: translateY(0); }
}

.playlist-content, .video-list {
  animation: fadeIn 0.3s ease;
}