/* AdminUpload.css */
.admin-upload {
    max-width: 600px;
    margin: 50px auto;
    padding: 20px;
    background: #141e30;
    border-radius: 8px;
    color: #ffffff;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.5);
  }
  
  .admin-upload h2 {
    font-size: 2rem;
    text-align: center;
    color: #00ffab;
    margin-bottom: 20px;
  }
  
  .upload-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .upload-form input[type="text"],
  .upload-form input[type="file"] {
    padding: 10px;
    border: none;
    border-radius: 6px;
    font-size: 1rem;
    background: #1e293b;
    color: #ffffff;
  }
  
  .upload-form button {
    background: linear-gradient(135deg, #00ffab, #0078ff);
    padding: 12px;
    font-size: 1rem;
    font-weight: bold;
    color: #ffffff;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: transform 0.3s ease, background 0.3s ease;
  }
  
  .upload-form button:hover {
    transform: scale(1.05);
    background: linear-gradient(135deg, #0078ff, #00f7ab);
  }
  
  .success-message {
    color: #10b981;
    font-weight: bold;
    text-align: center;
  }
  