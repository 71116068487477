/* Light Theme Hero Styles */
:root {
  --primary-color: #0066cc;
  --accent-color: #00cc99;
  --discord-color: #5865F2;
  --bg-color: #ffffff;
  --card-bg: #f5f5f5;
  --text-color: #333333;
  --text-secondary: #666666;
  --border-color: rgba(0, 0, 0, 0.1);
  --shadow-color: rgba(0, 0, 0, 0.05);
}

.hero {
  position: relative;
  min-height: 100vh;
  width: 100%;
  overflow: hidden;
  background-color: var(--bg-color);
  color: var(--text-color);
  padding: 4rem 2rem 6rem;
}

/* Background pattern styling */
.background-pattern {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  opacity: 0.3;
  pointer-events: none;
}

/* Main content layout */
.hero-content {
  position: relative;
  z-index: 1;
  display: flex;
  margin: 0 auto;
  max-width: 1200px;
  gap: 2rem;
}

@media (max-width: 992px) {
  .hero-content {
    flex-direction: column;
  }
}

/* Text column styling */
.text-column {
  flex: 1;
  max-width: 600px;
}

.hero-greeting {
  font-size: 1.1rem;
  margin-bottom: 1rem;
  color: var(--text-secondary);
  background: rgba(0, 0, 0, 0.03);
  display: inline-block;
  padding: 0.5rem 1rem;
  border-radius: 50px;
}

.hero-title {
  font-size: 3rem;
  font-weight: 800;
  line-height: 1.2;
  margin-bottom: 1.5rem;
  color: var(--text-color);
}

.hero-title .highlight {
  color: var(--accent-color);
  position: relative;
  display: inline-block;
  background-image: linear-gradient(transparent 70%, rgba(0, 204, 153, 0.2) 30%);
  background-repeat: no-repeat;
  background-size: 0% 100%;
  padding: 0 0.2rem;
}

.hero-tagline {
  font-size: 1.2rem;
  line-height: 1.6;
  color: var(--text-secondary);
  margin-bottom: 2rem;
  max-width: 540px;
}

/* Featured benefit component */
.featured-benefit {
  background: rgba(0, 102, 204, 0.05);
  border-left: 3px solid var(--primary-color);
  padding: 1rem;
  border-radius: 0 8px 8px 0;
  margin-bottom: 2rem;
  min-height: 60px;
  display: flex;
  align-items: center;
}

.benefit-content {
  display: flex;
  align-items: center;
  gap: 0.8rem;
}

.benefit-icon {
  font-size: 1.8rem;
}

.benefit-title {
  font-weight: 600;
  font-size: 1.1rem;
  color: var(--text-color);
}

/* Button styling */
.hero-buttons {
  display: flex;
  gap: 1rem;
  margin-bottom: 2rem;
  flex-wrap: wrap;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.8rem 1.5rem;
  border-radius: 8px;
  font-weight: 600;
  transition: all 0.3s ease;
  text-decoration: none;
  gap: 0.5rem;
  box-shadow: 0 4px 6px var(--shadow-color);
}

.button:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 12px var(--shadow-color);
}

.button-icon {
  font-size: 1.2rem;
}

.primary-cta {
  background: linear-gradient(to right, var(--discord-color), #7289da);
  color: white;
  position: relative;
  overflow: hidden;
}

.primary-cta::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, rgba(255,255,255,0), rgba(255,255,255,0.2), rgba(255,255,255,0));
  transform: translateX(-100%);
  transition: transform 0.6s ease;
}

.primary-cta:hover::after {
  transform: translateX(100%);
}

.discord-btn {
  background: linear-gradient(to right, var(--discord-color), #7289da);
  color: white;
}

.whatsapp-btn {
  background-color: #25D366;
  color: white;
}

.download-btn {
  background: var(--card-bg);
  color: var(--text-color);
  border: 1px solid var(--border-color);
}

/* Trading focus areas */
.trading-focus {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}

.focus-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background: var(--card-bg);
  padding: 0.5rem 1rem;
  border-radius: 8px;
  border: 1px solid var(--border-color);
}

.focus-icon {
  font-size: 1.2rem;
}

.focus-text {
  color: var(--text-color);
  font-weight: 500;
}

/* Image column styling */
.image-column {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-wrapper {
  position: relative;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 10px 30px var(--shadow-color);
  max-width: 500px;
  width: 100%;
  aspect-ratio: 4/3;
}

.hero-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.4));
}

/* Discord badge on image */
.discord-badge {
  position: absolute;
  bottom: 20px;
  right: 20px;
  background: var(--discord-color);
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 50px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-weight: 600;
  font-size: 0.9rem;
  box-shadow: 0 4px 12px rgba(88, 101, 242, 0.3);
  z-index: 5;
}

.discord-badge-icon {
  font-size: 1.2rem;
}

/* Visual element styling for charts */
.visual-element {
  width: 100%;
  max-width: 500px;
}

.trading-chart {
  position: relative;
  background: var(--card-bg);
  border: 1px solid var(--border-color);
  border-radius: 12px;
  padding: 1.5rem;
  margin-bottom: 1.5rem;
  min-height: 300px;
  box-shadow: 0 8px 16px var(--shadow-color);
}

.chart-annotations {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.chart-annotation {
  position: absolute;
  background: var(--primary-color);
  color: white;
  padding: 0.3rem 0.7rem;
  border-radius: 4px;
  font-size: 0.8rem;
  font-weight: 600;
  box-shadow: 0 2px 4px var(--shadow-color);
}

.chart-annotation::after {
  content: '';
  position: absolute;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: var(--accent-color);
}

.chart-annotation.entry::after {
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
}

.chart-annotation.resistance::after {
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
}

.chart-annotation.target::after {
  left: -10px;
  top: 50%;
  transform: translateY(-50%);
}

/* Enhanced Community stats styling */
.community-stats {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.stat-item {
  flex: 1;
  min-width: 120px;
  background: var(--card-bg);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  box-shadow: 0 4px 8px var(--shadow-color);
}

.stat-icon {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  color: var(--accent-color);
}

.stat-number {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 0.3rem;
  color: var(--primary-color);
}

.stat-label {
  font-size: 0.9rem;
  color: var(--text-secondary);
}

/* Discord community highlight */
.discord-community-highlight {
  display: flex;
  align-items: center;
  gap: 1rem;
  background: linear-gradient(to right, rgba(88, 101, 242, 0.1), rgba(88, 101, 242, 0.02));
  padding: 1rem;
  border-radius: 8px;
  border-left: 3px solid var(--discord-color);
  box-shadow: 0 4px 8px var(--shadow-color);
}

.discord-icon {
  font-size: 2rem;
  color: var(--discord-color);
}

.discord-text {
  flex: 1;
}

.discord-text p {
  margin: 0;
  margin-bottom: 0.5rem;
  color: var(--text-color);
  font-weight: 500;
}

.discord-text a {
  color: var(--discord-color);
  font-weight: 600;
  text-decoration: none;
  transition: color 0.2s ease;
}

.discord-text a:hover {
  color: #7289da;
  text-decoration: underline;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .hero {
    padding: 2rem 1rem 4rem;
  }
  
  .hero-title {
    font-size: 2.2rem;
  }
  
  .hero-buttons {
    flex-direction: column;
    width: 100%;
  }
  
  .button {
    width: 100%;
    justify-content: center;
  }
  
  .community-stats {
    flex-direction: column;
  }
  
  .stat-item {
    flex-direction: row;
    justify-content: space-between;
    text-align: left;
    width: 100%;
  }
  
  .stat-icon {
    margin-bottom: 0;
    margin-right: 1rem;
  }
}