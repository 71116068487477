
.articles-section {
  padding: 100px 0 80px;
  background-color: var(--bg-color);
  color: var(--text-color);
  position: relative;
  overflow: hidden;
}

/* Add subtle background elements */
.articles-section::before {
  content: '';
  position: absolute;
  top: -150px;
  right: -150px;
  width: 400px;
  height: 400px;
  border-radius: 50%;
  background: radial-gradient(circle, rgba(0, 204, 153, 0.03) 0%, transparent 70%);
  pointer-events: none;
  z-index: 0;
}

.articles-section::after {
  content: '';
  position: absolute;
  bottom: -100px;
  left: -100px;
  width: 300px;
  height: 300px;
  border-radius: 50%;
  background: radial-gradient(circle, rgba(0, 102, 204, 0.03) 0%, transparent 70%);
  pointer-events: none;
  z-index: 0;
}

.articles-container {
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 20px;
  position: relative;
  z-index: 1;
}

/* Enhanced Header Styles */
.articles-header {
  text-align: center;
  margin-bottom: 60px;
  position: relative;
}

.articles-title {
  font-size: 2.75rem;
  font-weight: 800;
  margin-bottom: 16px;
  position: relative;
  display: inline-block;
  letter-spacing: -0.5px;
}

.articles-title .highlight {
  color: var(--accent-color);
  position: relative;
}

.articles-title .highlight::after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 3px;
  background: var(--gradient-primary);
  border-radius: 3px;
}

.articles-subtitle {
  font-size: 1.125rem;
  color: var(--text-secondary);
  max-width: 600px;
  margin: 0 auto 24px;
  line-height: 1.6;
}

.view-all-btn {
  display: inline-block;
  background-color: transparent;
  border: 1px solid var(--border-color);
  color: var(--text-color);
  padding: 10px 24px;
  border-radius: 30px;
  font-size: 0.9rem;
  font-weight: 500;
  text-decoration: none;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.view-all-btn:hover {
  background-color: rgba(255, 255, 255, 0.05);
  transform: translateY(-3px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  border-color: rgba(255, 255, 255, 0.2);
}

/* Enhanced Articles Grid */
.articles-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 35px;
  margin-bottom: 70px;
}

@media (min-width: 768px) {
  .articles-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1024px) {
  .articles-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

/* Improved Article Card */
.article-card {
  background-color: var(--card-bg);
  border: 1px solid var(--border-color);
  border-radius: 16px;
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
  transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
  box-shadow: var(--card-shadow);
  position: relative;
}

.article-card:hover {
  transform: translateY(-8px);
  box-shadow: var(--card-hover-shadow);
  border-color: rgba(255, 255, 255, 0.15);
}

/* Article Image Enhancements */
.article-image-container {
  position: relative;
  height: 220px;
  overflow: hidden;
}

.article-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.8s ease;
}

.article-card:hover .article-image {
  transform: scale(1.08);
}

/* Overlay gradient on images */
.article-image-container::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.4));
  pointer-events: none;
}

.article-category {
  position: absolute;
  top: 16px;
  left: 16px;
  padding: 7px 14px;
  border-radius: 30px;
  font-size: 0.75rem;
  font-weight: 700;
  letter-spacing: 0.5px;
  z-index: 2;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

/* Enhanced Article Content */
.article-content {
  padding: 28px;
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
}

.article-meta {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  font-size: 0.85rem;
  color: var(--text-secondary);
}

.article-dot {
  margin: 0 8px;
  opacity: 0.6;
}

.article-title {
  font-size: 1.35rem;
  font-weight: 700;
  margin-bottom: 15px;
  line-height: 1.4;
  transition: color 0.3s ease;
  letter-spacing: -0.3px;
}

.article-card:hover .article-title {
  background: var(--gradient-primary);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
}

.article-description {
  font-size: 0.95rem;
  line-height: 1.7;
  color: var(--text-secondary);
  margin-bottom: 24px;
  flex: 1;
}

.article-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: auto;
  border-top: 1px solid var(--border-color);
  padding-top: 18px;
}

.article-author {
  font-size: 0.85rem;
  color: var(--text-secondary);
  font-weight: 500;
  display: flex;
  align-items: center;
}

/* Add author avatar */
.article-author::before {
  content: '';
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background-color: var(--primary-color);
  margin-right: 10px;
  background-image: var(--gradient-primary);
  display: inline-block;
}

.article-link {
  color: var(--accent-color);
  font-size: 0.9rem;
  font-weight: 600;
  text-decoration: none;
  display: flex;
  align-items: center;
  transition: all 0.3s ease;
  padding: 6px 12px;
  border-radius: 20px;
}

.article-link:hover {
  color: white;
  background-color: var(--accent-color);
}

.arrow-icon {
  margin-left: 6px;
  transition: transform 0.3s ease;
}

.article-link:hover .arrow-icon {
  transform: translateX(4px);
}

/* Enhanced Subscription Box */
.articles-subscription {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(45deg, rgba(0, 102, 204, 0.1), rgba(0, 204, 153, 0.1));
  border: 1px solid var(--border-color);
  border-radius: 20px;
  padding: 50px 30px;
  text-align: center;
  position: relative;
  overflow: hidden;
  box-shadow: var(--card-shadow);
}

/* Add decorative elements */
.articles-subscription::before {
  content: '';
  position: absolute;
  top: -50px;
  right: -50px;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background: radial-gradient(circle, rgba(0, 204, 153, 0.1) 0%, transparent 70%);
  pointer-events: none;
}

.articles-subscription::after {
  content: '';
  position: absolute;
  bottom: -50px;
  left: -50px;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background: radial-gradient(circle, rgba(0, 102, 204, 0.1) 0%, transparent 70%);
  pointer-events: none;
}

@media (min-width: 768px) {
  .articles-subscription {
    flex-direction: row;
    justify-content: space-between;
    text-align: left;
    padding: 50px;
  }
}

.subscription-content {
  margin-bottom: 30px;
  position: relative;
  z-index: 1;
}

@media (min-width: 768px) {
  .subscription-content {
    margin-bottom: 0;
    max-width: 50%;
  }
}

.subscription-content h3 {
  font-size: 1.75rem;
  font-weight: 700;
  margin-bottom: 16px;
  position: relative;
  display: inline-block;
}

.subscription-content h3::after {
  content: '';
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 50px;
  height: 3px;
  background: var(--gradient-primary);
  border-radius: 3px;
}

.subscription-content p {
  font-size: 1rem;
  color: var(--text-secondary);
  line-height: 1.7;
}

.subscription-form {
  display: flex;
  width: 100%;
  max-width: 450px;
  position: relative;
  z-index: 1;
}

.subscription-input {
  flex: 1;
  background-color: rgba(255, 255, 255, 0.05);
  border: 1px solid var(--border-color);
  border-right: none;
  border-radius: 12px 0 0 12px;
  padding: 16px 20px;
  font-size: 0.95rem;
  color: var(--text-color);
  outline: none;
  transition: all 0.3s ease;
}

.subscription-input:focus {
  background-color: rgba(255, 255, 255, 0.1);
  border-color: var(--accent-color);
  box-shadow: 0 0 0 3px rgba(0, 204, 153, 0.1);
}

.subscription-btn {
  background: var(--gradient-primary);
  color: white;
  border: none;
  border-radius: 0 12px 12px 0;
  padding: 0 28px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.subscription-btn:hover {
  transform: translateX(2px);
  box-shadow: 0 5px 15px rgba(0, 102, 204, 0.3);
}

/* Add a subtle pulse animation to the button */
.subscription-btn::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 150%;
  height: 150%;
  background: radial-gradient(circle, rgba(255, 255, 255, 0.3) 0%, transparent 70%);
  transform: translate(-50%, -50%) scale(0);
  opacity: 0;
  animation: pulse-animation 2s infinite;
  pointer-events: none;
}

@keyframes pulse-animation {
  0% {
    transform: translate(-50%, -50%) scale(0);
    opacity: 0;
  }
  50% {
    opacity: 0.1;
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0;
  }
}

/* Improved Responsive Adjustments */
@media (max-width: 640px) {
  .articles-title {
    font-size: 2.2rem;
  }
  
  .article-title {
    font-size: 1.2rem;
  }
  
  .subscription-form {
    flex-direction: column;
  }
  
  .subscription-input {
    border-radius: 12px;
    border-right: 1px solid var(--border-color);
    margin-bottom: 10px;
  }
  
  .subscription-btn {
    border-radius: 12px;
    padding: 14px 0;
  }
  
  .articles-subscription {
    padding: 35px 25px;
  }
}

/* Article Card Hover Animation */
@media (min-width: 1024px) {
  .articles-grid {
    grid-auto-rows: 1fr;
  }
  
  .article-card:hover {
    z-index: 5;
  }
}