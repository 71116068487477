/* Events Section Styles - Enhanced Theme with Dark Mode Support */
:root {
  /* Light Theme Variables */
  --primary-color: #0066cc;
  --accent-color: #00cc99;
  --gradient-primary: linear-gradient(135deg, #0066cc, #00cc99);
  --bg-color: #ffffff;
  --card-bg: #f9f9f9;
  --text-color: #2d3748;
  --text-secondary: #4a5568;
  --border-color: rgba(0, 0, 0, 0.08);
  --shadow-color: rgba(0, 0, 0, 0.05);
  --webinar-color: #00cc99;
  --workshop-color: #0066cc;
  --in-person-color: #cc6600;
  --card-hover-transform: translateY(-6px);
  --countdown-bg: rgba(0, 102, 204, 0.05);
  --button-hover-shadow: 0 6px 12px rgba(0, 102, 204, 0.15);
}

/* Dark Mode Support */
@media (prefers-color-scheme: dark) {
  :root {
    --primary-color: #4299e1;
    --accent-color: #38b2ac;
    --gradient-primary: linear-gradient(135deg, #4299e1, #38b2ac);
    --bg-color: #1a202c;
    --card-bg: #2d3748;
    --text-color: #f7fafc;
    --text-secondary: #e2e8f0;
    --border-color: rgba(255, 255, 255, 0.08);
    --shadow-color: rgba(0, 0, 0, 0.3);
    --countdown-bg: rgba(66, 153, 225, 0.1);
    --button-hover-shadow: 0 6px 12px rgba(66, 153, 225, 0.25);
  }
}

.community-events-section {
  padding: clamp(3rem, 8vw, 6rem) clamp(1rem, 5vw, 2rem);
  background-color: var(--bg-color);
  color: var(--text-color);
  transition: all 0.3s ease;
}

.section-container {
  max-width: 1200px;
  margin: 0 auto;
}

/* Section Header */
.section-header {
  text-align: center;
  margin-bottom: clamp(2rem, 6vw, 4rem);
}

.section-title {
  font-size: clamp(1.8rem, 5vw, 2.75rem);
  font-weight: 800;
  margin-bottom: 1.25rem;
  letter-spacing: -0.025em;
  line-height: 1.2;
}

.section-title .highlight {
  color: var(--accent-color);
  position: relative;
  display: inline-block;
}

.section-title .highlight::after {
  content: '';
  position: absolute;
  bottom: 0.1em;
  left: 0;
  width: 100%;
  height: 0.3em;
  background-color: rgba(0, 204, 153, 0.2);
  z-index: -1;
  border-radius: 2px;
  transform: skewX(-5deg);
}

.section-description {
  font-size: clamp(1rem, 2vw, 1.15rem);
  color: var(--text-secondary);
  max-width: 700px;
  margin: 0 auto;
  line-height: 1.7;
}

/* Event Filters */
.event-filters {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 0.75rem;
  margin-bottom: clamp(2rem, 5vw, 3.5rem);
  width: 100%;
  overflow-x: visible;
  flex-direction: row;
}

.filter-btn {
  background-color: transparent;
  border: 1px solid var(--border-color);
  color: var(--text-secondary);
  padding: 0.65rem 1.5rem;
  border-radius: 9999px;
  font-size: 0.95rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;
  overflow: hidden;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.filter-btn:hover {
  border-color: var(--primary-color);
  color: var(--primary-color);
  box-shadow: 0 2px 8px rgba(0, 102, 204, 0.15);
}

.filter-btn.active {
  background: var(--gradient-primary);
  border-color: transparent;
  color: white;
  box-shadow: 0 4px 10px rgba(0, 102, 204, 0.2);
}

.filter-btn.active::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, rgba(255, 255, 255, 0.1), transparent);
  transform: translateX(-100%);
  animation: shine 1.5s infinite;
}

@keyframes shine {
  100% {
    transform: translateX(100%);
  }
}

/* Events Grid */
.events-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(min(100%, 550px), 1fr));
  gap: clamp(1.5rem, 4vw, 2.5rem);
  margin-bottom: clamp(3rem, 6vw, 5rem);
}

/* Event Cards */
.event-card {
  background-color: var(--card-bg);
  border: 1px solid var(--border-color);
  border-radius: 16px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
  box-shadow: 0 10px 25px var(--shadow-color);
  transition: transform 0.4s cubic-bezier(0.3, 0, 0.2, 1.5), 
              box-shadow 0.4s cubic-bezier(0.3, 0, 0.2, 1);
  position: relative;
  isolation: isolate;
}

.event-card:hover {
  transform: var(--card-hover-transform);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.12);
}

.event-card:hover::after {
  opacity: 1;
}

.event-card::after {
  content: '';
  position: absolute;
  inset: 0;
  border-radius: 16px;
  border: 2px solid transparent;
  background: var(--gradient-primary) border-box;
  -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  opacity: 0;
  transition: opacity 0.4s ease;
  z-index: -1;
  pointer-events: none;
}

.event-image {
  position: relative;
  height: 220px;
}

.event-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease;
}

.event-card:hover .event-image img {
  transform: scale(1.05);
}

.event-type-badge {
  position: absolute;
  top: 16px;
  right: 16px;
  padding: 0.45rem 1.1rem;
  border-radius: 9999px;
  font-size: 0.8rem;
  font-weight: 700;
  letter-spacing: 0.01em;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(4px);
  transition: all 0.3s ease;
}

.event-card:hover .event-type-badge {
  transform: translateY(-3px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.event-content {
  padding: 1.75rem;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.event-title {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 1.25rem;
  line-height: 1.3;
  color: var(--text-color);
  transition: color 0.3s ease;
}

.event-card:hover .event-title {
  background: var(--gradient-primary);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* Event Meta */
.event-meta {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 1.25rem;
  gap: 1rem;
}

.event-host {
  display: flex;
  flex-direction: column;
}

.host-name {
  font-weight: 700;
  margin-bottom: 0.25rem;
  color: var(--text-color);
}

.host-title {
  font-size: 0.9rem;
  color: var(--text-secondary);
}

.event-date {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--text-secondary);
  font-weight: 500;
}

.date-icon {
  font-size: 1.2rem;
}

.event-description {
  margin-bottom: 1.75rem;
  color: var(--text-secondary);
  line-height: 1.7;
  flex-grow: 1;
}

/* Event Footer */
.event-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 1.5rem;
  margin-top: auto;
  border-top: 1px solid var(--border-color);
  padding-top: 1.5rem;
}

.participants {
  display: flex;
  align-items: baseline;
  gap: 0.3rem;
}

.participant-count {
  font-size: 1.4rem;
  font-weight: 800;
  color: var(--accent-color);
}

.participant-label {
  font-size: 0.9rem;
  color: var(--text-secondary);
}

.countdown {
  display: flex;
  gap: 0.8rem;
  padding: 0.5rem 1rem;
  background-color: var(--countdown-bg);
  border-radius: 8px;
}

.countdown-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.count-value {
  font-size: 1.25rem;
  font-weight: 800;
  color: var(--primary-color);
}

.count-label {
  font-size: 0.75rem;
  font-weight: 600;
  color: var(--text-secondary);
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

.event-past {
  color: var(--text-secondary);
  font-style: italic;
  font-weight: 500;
  padding: 0.5rem 1rem;
  background-color: var(--countdown-bg);
  border-radius: 8px;
}

.register-btn {
  background: var(--gradient-primary);
  color: white;
  border: none;
  padding: 0.8rem 1.75rem;
  border-radius: 8px;
  font-weight: 700;
  font-size: 0.95rem;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
  white-space: nowrap;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
}

.register-btn::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0));
  transform: translateX(-100%);
  transition: transform 0.6s ease;
}

.register-btn:hover {
  transform: translateY(-3px);
  box-shadow: var(--button-hover-shadow);
}

.register-btn:hover::before {
  transform: translateX(100%);
}

.register-btn:active {
  transform: translateY(-1px);
}

/* No Events */
.no-events {
  grid-column: 1 / -1;
  text-align: center;
  padding: 3.5rem 2rem;
  background-color: var(--card-bg);
  border-radius: 16px;
  border: 2px dashed var(--border-color);
  color: var(--text-secondary);
  font-size: 1.1rem;
}

.view-all-btn {
  margin-top: 1.5rem;
  background-color: transparent;
  border: 2px solid var(--border-color);
  color: var(--text-color);
  padding: 0.8rem 2rem;
  border-radius: 8px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
}

.view-all-btn:hover {
  background: var(--gradient-primary);
  border-color: transparent;
  color: white;
  box-shadow: var(--button-hover-shadow);
}

/* Events Footer */
.events-footer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: clamp(1.5rem, 4vw, 2.5rem);
  margin-top: clamp(2.5rem, 6vw, 4rem);
}

.suggest-event, .event-calendar {
  background-color: var(--card-bg);
  border: 1px solid var(--border-color);
  border-radius: 16px;
  padding: clamp(1.5rem, 4vw, 2.5rem);
  box-shadow: 0 10px 25px var(--shadow-color);
  transition: transform 0.4s ease, box-shadow 0.4s ease;
}

.suggest-event:hover, .event-calendar:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
}

.suggest-event h3, .event-calendar h3 {
  font-size: 1.35rem;
  margin-bottom: 1rem;
  color: var(--text-color);
  font-weight: 700;
}

.suggest-event p, .event-calendar p {
  color: var(--text-secondary);
  margin-bottom: 1.5rem;
  line-height: 1.7;
}

.suggest-btn, .calendar-btn {
  display: inline-flex;
  align-items: center;
  gap: 0.6rem;
  background-color: var(--card-bg);
  border: 2px solid var(--border-color);
  color: var(--text-color);
  padding: 0.8rem 1.5rem;
  border-radius: 8px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
}

.suggest-btn:hover, .calendar-btn:hover {
  border-color: var(--primary-color);
  color: var(--primary-color);
  background-color: rgba(0, 102, 204, 0.05);
  transform: translateY(-3px);
  box-shadow: 0 6px 12px rgba(0, 102, 204, 0.1);
}

.calendar-icon {
  font-size: 1.2rem;
}

.calendar-info {
  font-size: 0.9rem;
  margin-top: 1.2rem;
  opacity: 0.85;
}

/* Fixes for tab layout */
#events .tab-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  overflow-x: auto;
  margin-bottom: 20px;
  gap: 10px;
  padding: 5px 0;
}

#events .tab {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  background-color: var(--card-bg);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease;
  white-space: nowrap;
  font-weight: 500;
  color: var(--text-color);
}

#events .tab:hover {
  border-color: var(--primary-color);
  color: var(--primary-color);
}

#events .tab.active {
  background-color: var(--primary-color);
  color: white;
  border-color: var(--primary-color);
}

/* Fix for the vertical tabs in the left panel */
.left-panel-tabs {
  display: flex;
  flex-direction: row !important; /* Force horizontal layout */
  justify-content: space-between;
  width: 100%;
  padding: 10px;
  border-bottom: 1px solid var(--border-color);
  margin-bottom: 20px;
  overflow-x: auto;
}

.left-panel-tabs .tab {
  flex: 1;
  text-align: center;
  padding: 10px;
  cursor: pointer;
  border-bottom: 2px solid transparent;
  transition: all 0.2s ease;
}

.left-panel-tabs .tab.active {
  border-bottom-color: var(--primary-color);
  color: var(--primary-color);
  font-weight: 600;
}

/* Custom Scroll for horizontal overflow on smaller screens */
@media (max-width: 768px) {
  .events-grid {
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    scrollbar-width: thin;
    scrollbar-color: var(--primary-color) var(--bg-color);
    padding-bottom: 2rem;
    gap: 1.5rem;
  }
  
  .events-grid::-webkit-scrollbar {
    height: 6px;
  }
  
  .events-grid::-webkit-scrollbar-track {
    background: var(--card-bg);
    border-radius: 10px;
  }
  
  .events-grid::-webkit-scrollbar-thumb {
    background: var(--primary-color);
    border-radius: 10px;
  }
  
  .events-grid > * {
    scroll-snap-align: start;
    min-width: min(100%, 350px);
  }
  
  /* Ensure horizontal tabs on mobile */
  .left-panel-tabs {
    flex-wrap: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  
  .tab-container {
    flex-wrap: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

/* Responsive Adjustments */
@media (max-width: 992px) {
  .section-title {
    font-size: clamp(1.6rem, 4vw, 2.2rem);
  }
  
  .event-footer {
    flex-direction: column;
    align-items: flex-start;
    gap: 1.2rem;
  }
  
  .countdown {
    width: 100%;
    justify-content: space-around;
  }
}

@media (max-width: 768px) {
  .community-events-section {
    padding: clamp(2rem, 4vw, 3rem) clamp(1rem, 2vw, 1.5rem);
  }
  
  .section-title {
    font-size: clamp(1.5rem, 4vw, 1.8rem);
  }
  
  .section-description {
    font-size: clamp(0.9rem, 2vw, 1rem);
  }
  
  .event-meta {
    flex-direction: column;
    gap: 0.75rem;
  }
  
  .register-btn {
    width: 100%;
    text-align: center;
    padding: 0.8rem;
  }
  
  .events-footer {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 480px) {
  .event-title {
    font-size: 1.3rem;
  }
  
  .event-image {
    height: 180px;
  }
  
  .event-content {
    padding: 1.2rem;
  }
  
  .filter-btn {
    font-size: 0.85rem;
    padding: 0.5rem 1rem;
  }
  
  .countdown {
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    padding: 0.75rem;
  }
  
  .event-footer {
    padding-top: 1.2rem;
    gap: 1rem;
  }
  
  .participants {
    width: 100%;
    justify-content: center;
  }
}