/* community.css - Complete CSS for the Community page with Free/Premium tabs */

/* Main Container */
.community-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

/* Header Section */
.community-header {
  background: linear-gradient(135deg, #1a237e 0%, #2b7de9 100%);
  color: white;
  border-radius: 12px;
  padding: 40px 30px;
  margin-bottom: 30px;
  text-align: center;
}

.header-content {
  max-width: 800px;
  margin: 0 auto;
}

.community-header h1 {
  font-size: 2.5rem;
  margin-bottom: 15px;
  font-weight: 700;
}

.text-highlight {
  color: #4ecdc4;
}

.header-description {
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 25px;
  opacity: 0.9;
}

.submit-community-btn {
  background-color: #4ecdc4;
  color: #1a237e;
  border: none;
  border-radius: 30px;
  padding: 12px 25px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  display: inline-flex;
  align-items: center;
  gap: 8px;
}

.submit-community-btn:hover {
  background-color: white;
  transform: translateY(-2px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

/* Community Tools Section */
.community-tools {
  margin-bottom: 30px;
}

.search-box {
  display: flex;
  align-items: center;
  background-color: white;
  border-radius: 8px;
  padding: 10px 15px;
  margin-bottom: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
}

.search-icon {
  color: #666;
  margin-right: 10px;
  font-size: 16px;
}

.search-box input {
  flex: 1;
  border: none;
  padding: 8px 0;
  font-size: 1rem;
  outline: none;
}

/* Group Type Tabs */
.group-type-tabs {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  background: #ffffff;
  border-radius: 8px;
  padding: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
}

.group-type-tab {
  flex: 1;
  padding: 12px 15px;
  text-align: center;
  font-weight: 600;
  border: none;
  background: transparent;
  color: #666;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.group-type-tab i {
  font-size: 14px;
}

.group-type-tab.active {
  background-color: #2b7de9;
  color: white;
}

.group-type-tab:hover:not(.active) {
  background-color: #f0f4f8;
  color: #2b7de9;
}

/* Filter Tabs */
.filter-tabs {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  overflow-x: auto;
  padding-bottom: 5px;
}

.filter-tab {
  border: none;
  background-color: #f0f4f8;
  color: #555;
  padding: 10px 20px;
  margin-right: 10px;
  border-radius: 30px;
  font-size: 0.9rem;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 6px;
  white-space: nowrap;
}

.filter-tab.active {
  background-color: #2b7de9;
  color: white;
}

.filter-tab:hover:not(.active) {
  background-color: #e0e0e0;
}

/* Platform Filters */
.platform-filters {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  overflow-x: auto;
  padding-bottom: 5px;
}

.platform-filter {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  background-color: #f0f4f8;
  color: #666;
  margin-right: 10px;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
}

.platform-filter.active {
  background-color: #2b7de9;
  color: white;
}

.platform-filter:hover:not(.active) {
  background-color: #e0e0e0;
  transform: translateY(-2px);
}

/* Communities Grid */
.communities-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 20px;
  margin-bottom: 40px;
}

/* Free & Paid Communities */
.community-item {
  background-color: white;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.08);
  transition: all 0.3s ease;
  position: relative;
  display: flex;
  flex-direction: column;
}

.community-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.12);
}

.community-item.free-community {
  border-top: 3px solid #4caf50;
}

.community-item.paid-community {
  border-top: 3px solid #ffc107;
}

.community-image-container {
  position: relative;
  height: 180px;
}

.community-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.community-platform {
  position: absolute;
  bottom: 15px;
  left: 15px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
}

.platform-icon.telegram {
  color: #0088cc;
}

.platform-icon.discord {
  color: #7289da;
}

.platform-icon.youtube {
  color: #ff0000;
}

.platform-icon.twitter {
  color: #1da1f2;
}

.platform-icon.whatsapp {
  color: #25d366;
}

.platform-icon.instagram {
  color: #C13584;
}

.verified-badge {
  position: absolute;
  top: 15px;
  left: 15px;
  background-color: rgba(255, 255, 255, 0.8);
  color: #4caf50;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.1rem;
}

.premium-badge {
  position: absolute;
  top: 15px;
  right: 15px;
  background-color: #ffc107;
  color: #000;
  border-radius: 3px;
  padding: 3px 8px;
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 5px;
  z-index: 2;
}

.premium-badge i {
  color: #fff;
  font-size: 10px;
}

.community-content {
  padding: 20px;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.community-info {
  flex: 1;
}

.community-header-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.community-name {
  font-size: 1.3rem;
  margin: 0;
  font-weight: 700;
  color: #333;
}

.community-price {
  background-color: #ffeeba;
  color: #856404;
  font-weight: 600;
  font-size: 0.85rem;
  padding: 3px 10px;
  border-radius: 20px;
}

.community-meta {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  font-size: 0.85rem;
  color: #666;
  margin-bottom: 10px;
}

.community-country, .community-members, .community-type {
  display: flex;
  align-items: center;
  gap: 5px;
}

.community-type i {
  font-size: 12px;
}

.community-creator {
  font-size: 0.85rem;
  margin-bottom: 15px;
  color: #777;
}

.username {
  color: #2b7de9;
  font-weight: 600;
}

.community-description {
  margin-bottom: 15px;
  font-size: 0.95rem;
  color: #444;
  line-height: 1.5;
}

.community-benefits {
  margin: 15px 0;
  padding: 10px;
  background-color: #f8f9fa;
  border-radius: 6px;
}

.community-benefits h4 {
  font-size: 14px;
  margin-bottom: 8px;
  color: #555;
}

.community-benefits ul {
  list-style: none;
  padding-left: 0;
  margin: 0;
}

.community-benefits li {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 5px;
  font-size: 13px;
  color: #666;
}

.community-benefits li i {
  color: #4caf50;
  font-size: 12px;
}

.community-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 10px;
}

.tag {
  background-color: #f0f4f8;
  color: #555;
  padding: 3px 10px;
  border-radius: 20px;
  font-size: 0.8rem;
}

.community-actions {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}

.join-button {
  flex: 1;
  background-color: #2b7de9;
  color: white;
  text-align: center;
  padding: 10px;
  border-radius: 6px;
  font-weight: 600;
  text-decoration: none;
  transition: all 0.3s ease;
}

.join-button:hover {
  background-color: #1e63c4;
}

.join-button.premium-join {
  background-color: #ffc107;
  color: #000;
}

.join-button.premium-join:hover {
  background-color: #e0a800;
}

.details-button {
  background-color: #f0f4f8;
  color: #555;
  border: none;
  padding: 10px 15px;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 8px;
}

.details-button:hover {
  background-color: #e0e0e0;
}

/* No Communities */
.no-communities {
  grid-column: 1 / -1;
  text-align: center;
  padding: 60px 20px;
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.08);
}

.no-communities i {
  font-size: 3rem;
  color: #ddd;
  margin-bottom: 20px;
}

.no-communities h3 {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 10px;
}

.no-communities p {
  color: #777;
}

/* CTA Section */
.community-cta {
  background: linear-gradient(135deg, #4ecdc4 0%, #33b5e5 100%);
  border-radius: 12px;
  padding: 40px 30px;
  text-align: center;
  color: white;
}

.cta-content {
  max-width: 700px;
  margin: 0 auto;
}

.cta-content h2 {
  font-size: 2rem;
  margin-bottom: 15px;
}

.cta-content p {
  font-size: 1.1rem;
  margin-bottom: 25px;
  opacity: 0.9;
}

.cta-button {
  background-color: white;
  color: #4ecdc4;
  border: none;
  border-radius: 30px;
  padding: 12px 30px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
}

.cta-button:hover {
  background-color: #1a237e;
  color: white;
  transform: translateY(-2px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

/* Modal */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  padding: 20px;
}

.community-submit-modal {
  background-color: white;
  border-radius: 12px;
  width: 100%;
  max-width: 700px;
  max-height: 90vh;
  overflow-y: auto;
}

.modal-header {
  padding: 20px;
  border-bottom: 1px solid #eee;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-header h2 {
  font-size: 1.5rem;
  color: #333;
  margin: 0;
}

.close-modal {
  background: none;
  border: none;
  font-size: 1.3rem;
  color: #777;
  cursor: pointer;
  transition: color 0.3s ease;
}

.close-modal:hover {
  color: #333;
}

.modal-content {
  padding: 20px;
}

.modal-description {
  margin-bottom: 20px;
  color: #666;
}

/* Form Styles */
.community-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.form-group label {
  font-weight: 600;
  color: #444;
  font-size: 0.95rem;
}

.form-group input,
.form-group select,
.form-group textarea {
  padding: 10px 12px;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 0.95rem;
}

.form-group input:focus,
.form-group select:focus,
.form-group textarea:focus {
  outline: none;
  border-color: #2b7de9;
}

.form-row {
  display: flex;
  gap: 15px;
}

.form-row .form-group {
  flex: 1;
}

.file-upload {
  position: relative;
}

.file-upload input[type="file"] {
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.file-upload label {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 12px;
  background-color: #f0f4f8;
  border-radius: 6px;
  cursor: pointer;
  color: #555;
  transition: all 0.3s ease;
}

.file-upload:hover label {
  background-color: #e0e0e0;
}

.checkbox-group {
  margin: 15px 0;
}

.checkbox-label {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.checkbox-label input[type="checkbox"] {
  width: 18px;
  height: 18px;
}

.form-actions {
  display: flex;
  gap: 15px;
  margin-top: 10px;
}

.submit-form-btn,
.cancel-btn {
  padding: 12px 20px;
  border-radius: 6px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
}

.submit-form-btn {
  background-color: #2b7de9;
  color: white;
  border: none;
  flex: 1;
}

.submit-form-btn:hover {
  background-color: #1e63c4;
}

.submit-form-btn:disabled {
  background-color: #aaa;
  cursor: not-allowed;
}

.cancel-btn {
  background-color: #f0f4f8;
  color: #555;
  border: none;
}

.cancel-btn:hover {
  background-color: #e0e0e0;
}

small {
  color: #888;
  font-size: 0.85rem;
}

.error {
  color: #dc3545;
  font-size: 0.85rem;
  margin-top: 5px;
}

/* Success Message */
.success-message {
  text-align: center;
  padding: 30px 20px;
}

.success-message i {
  font-size: 4rem;
  color: #4caf50;
  margin-bottom: 20px;
}

.success-message h3 {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 10px;
}

.success-message p {
  color: #666;
}

/* Responsive Styles */
@media (max-width: 992px) {
  .communities-container {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }
}

@media (max-width: 768px) {
  .community-header {
    padding: 30px 20px;
  }
  
  .community-header h1 {
    font-size: 2rem;
  }
  
  .header-description {
    font-size: 1rem;
  }
  
  .form-row {
    flex-direction: column;
    gap: 15px;
  }
  
  .group-type-tabs {
    flex-direction: column;
    gap: 5px;
    padding: 10px;
  }
  
  .group-type-tab {
    width: 100%;
  }
  
  .community-header-row {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }
  
  .community-price {
    align-self: flex-start;
  }
}

@media (max-width: 576px) {
  .communities-container {
    grid-template-columns: 1fr;
  }
  
  .community-meta {
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;
  }
  
  .community-actions {
    flex-direction: column;
  }
  
  .premium-badge {
    top: 10px;
    right: 10px;
    font-size: 10px;
    padding: 2px 6px;
  }
}